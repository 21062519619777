import React from 'react';
import { staff } from './theory.js';
import makeClick from './enter.js';
import removeClick from './enter.js';

export default class Staff extends React.Component {
    constructor(props) {
        super(props);

        this.id = "widget" + Math.random();

        this.renderate = this.renderate.bind(this);
    }

    renderate() {
        let params = JSON.parse(JSON.stringify(this.props.params));

        //sizing based on type of question
        params.height = 300;
        switch(this.props.params.drawType) {
            case "note":
                //params.width = window.innerWidth - document.getElementById("left-side").getBoundingClientRect().width;
                if (window.innerWidth>600)
                    params.width = window.innerWidth - document.getElementById("left-side").getBoundingClientRect().width;
                else
                    params.width = window.innerWidth;
                //if (this.props.question.right.displayType=="multipleStaff"&&window.innerWidth<=600) params.width = params.width / 2;
                //params.width = Math.min(params.width,600);
                break;
            default:
                params.width = 300;
                break;
        }
        this.tool = new staff(this.id,params,this);
        this.tool.initialize();

        let self = this;
        if (this.props.question.right.needsCheck) makeClick(
            function() {
                self.props.answer(self.tool.exportData())
            }
        )
    }

    //exportData() {
    //    return this.tool.exportData();
    //}

    componentDidUpdate() {
        this.renderate();
    }

    componentDidMount() {
        this.renderate();
        window.addEventListener('resize',this.renderate);
        //window.addEventListener('orientationchange',this.renderate);
    }

    /*componentWillUnmount() {
        removeClick()
    }*/

    render() {
        return (
            <div className="scale-widget" id={this.id}/>
        )
    }
}