import React from "react";
import { StytchLogin } from "@stytch/react";
import { Products } from "@stytch/vanilla-js";
import logo from './../theorybot.png';
import { useStytch } from "@stytch/react";
import matthew from './../theorybotlogo.png';

const STYTCH_URL = process.env.REACT_APP_URL;//process.env.NODE_ENV=='development'?'https://localhost:3000':'https://psu.noizy.io';

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch

This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/
const Login = () => {
  const stytch = useStytch();
  const styles = {
    container: {
      width: "100%",
    },
    buttons: {
      primary: {
        backgroundColor: "#4A37BE",
        borderColor: "#4A37BE",
      },
    },
  };
  const config = {
    passwordOptions: {
      loginExpirationMinutes: 30,
      loginRedirectURL: STYTCH_URL+'/authenticate',
      resetPasswordExpirationMinutes: 30,
      resetPasswordRedirectURL: STYTCH_URL+'/reset',
    },
    products: [
      Products.passwords,
    ],
  };

  return <div style={{background:"black",height:"calc(100vh - 2em)",paddingTop:'2em'}}>
      {/*<div className="topnav" id="myTopnav">
          <div className="navlogo-holder"><img className="navlogo" src={logo}></img></div>
      </div>*/}
      <div className="botdiv-holder" style={{marginBottom:"2em"}}>
          <div id="bot" className="botdiv2 bert atrest" style={{background:"#b19dd0"}}></div>
          <img style={{zIndex:1000000,background:'white'}} className="botdiv" src={matthew}></img>
      </div>
      <div style={{maxWidth:'600px',margin:'auto'}}>
        <div style={{maxWidth:'600px'}}>
          <StytchLogin config={config} styles={styles} />
        </div>
      </div>
    </div>
};

export default Login;