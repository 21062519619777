import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    queue: [],
    index: null,
};

export const tutorSlice = createSlice({
  name: 'tutor',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPageQueue(state, action) {
        state.queue = action.payload;
    },
    setQueueIndex(state, action) {
        state.index = action.payload;
    },
  },
});

export const { setPageQueue, setQueueIndex } = tutorSlice.actions;

export default tutorSlice.reducer;
