import ReactHowler from 'react-howler'
import { useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';

var audioCtx;
var source;
var setup = false;
var analyser;
function setupAudio(player) {
    //let audio = player.current._howler._sounds[0]._node;
    let audio = document.getElementById("aud");
    if (!audioCtx)
        audioCtx = new(window.AudioContext || window.webkitAudioContext)();
    if (!source)
        source = audioCtx.createMediaElementSource(audio);

    // Create an analyser
    if (!analyser) {
        analyser = audioCtx.createAnalyser();
        analyser.fftSize = 2 ** 8;
    }

    // Connect parts
    source.connect(analyser);
    analyser.connect(audioCtx.destination);
}

export default function Matthew(props) {
    const matthew = useSelector(state => state.matthew)
    const tutor = useSelector(state => state.tutor)
    const player = useRef('')
    const tracker = useRef(false)
    const voice = "openai"; // polly | deep

    //do the array shit here?
    const URLFromBuffer = () => {
        var ByteArray = tutor.queue[tutor.index].left.audio.data;
        var arrayBufferView = new Uint8Array(ByteArray)
        var blob = new Blob( [ arrayBufferView ], { type: 'music/mp3' } )
        var howlSource = URL.createObjectURL(blob)
        return howlSource;
    }

    const startAnimation = () => {
        let pos = document.getElementById("aud").currentTime;
        if (pos > 0) {
            //turn on
            if (!setup) setupAudio(player);

            LoadOrText('text');

            let bufferLength = analyser.frequencyBinCount;
            let dataArray = new Float32Array(bufferLength);
            analyser.getFloatTimeDomainData(dataArray);
            var sum = 0;
            for (var i=0; i<dataArray.length; i++) sum += dataArray[i];
            document.getElementById("bot").style.height = (110+2*Math.abs(sum)) + "px";
            document.getElementById("bot").style.width = (110+2*Math.abs(sum)) + "px";
        }
        if (!tracker.current)
            requestAnimationFrame(startAnimation)
    }

    const stopAnimation = () => {
        tracker.current = true;
        console.log("stop")
        document.getElementById("bot").classList.add("atrest");
    }

    return <div>
        <audio crossOrigin="anonymous" id="aud" onEnded={stopAnimation} onPlay={()=>{tracker.current=false; document.getElementById("bot").classList.remove("atrest"); startAnimation();}} src={process.env.REACT_APP_API_URL+"/generate?type="+voice+"&text="+tutor.queue[tutor.index].left.text} autoPlay={true} preload="none"></audio>
    </div>
}

function LoadOrText(tog) {
    switch(tog) {
        case 'load':
            document.getElementById("the-loader").style.display = 'flex';
            document.getElementById("the-text").style.visibility = 'hidden';
            break;
        case 'text':
            document.getElementById("the-loader").style.display = 'none';
            document.getElementById("the-text").style.visibility = 'visible';
            if (document.getElementById("textage"))
                document.getElementById("textage").focus();
            break;
    }
}

function toggle(id,bool) {
    document.getElementById(id).style.display = bool ? "" : "none";
}