import { useState, useEffect } from 'react';
import axios from 'axios';
import Matthew from './Matthew.js';
import { useDispatch, useSelector } from 'react-redux';
import { setPageQueue, setQueueIndex } from './slices/tutor/tutorSlice';
import { setTalking } from './slices/matthew/matthewSlice';
import makeClick from './enter.js';
import removeClick from './enter.js';
import Staff from './Staff.js';
import { useStytch } from "@stytch/react";
import logo from './theorybot.png';
import matthew from './theorybotlogo.png';

//set axios defaults
axios.defaults.withCredentials = true;

//const THESERVER = 'http://34.132.64.85:3000/identifyNotes';
const THESERVER = process.env.REACT_APP_API_URL;
var STUDENT_ID;

//left side is text, right side is display shit
export default function Tutor() {
    const [loading, setLoading] = useState(true);
    const [initialAction, setInitialAction] = useState(false);
    const dispatch = useDispatch();
    const stytch = useStytch();

    const funcarino = () => {
        var x = document.getElementById("myTopnav");
        if (x.className === "topnav") {
          x.className += " responsive";
        } else {
          x.className = "topnav";
        }
    }

    useEffect(()=>{
        const load = async () => {
            let res = await axios.get(THESERVER+'/start');
            //let res = await axios.get('/initialize');
            //let res = await axios.post(THESERVER+'/intro');
            STUDENT_ID = "4";//res.data.studentID;
            //
            setLoading(false);
            dispatch(
                setPageQueue(res.data)
            )
            dispatch(
                setQueueIndex(0)
            )
            dispatch(
                setTalking(true)
            )
        }

        load()
    },[])

    if (loading) return <div>
        <div className="topnav" id="myTopnav">
          <div className="navlogo-holder"><img className="navlogo" src={logo}></img></div>
          <div><button className="logout-button" onClick={() => stytch.session.revoke()}>Logout</button></div>
        </div>
        <div className="initial">
            <div className="botdiv-holder" style={{marginBottom:"2em"}}>
                <div id="bot" className="botdiv2 bert atrest" style={{background:"#b19dd0"}}></div>
                <img style={{zIndex:1000000,background:'white'}} className="botdiv" src={matthew}></img>
            </div>
            <div className="loader"></div>
        </div>
    </div>

    return (
        <div>
            <div className="topnav" id="myTopnav">
                <div className="navlogo-holder"><img className="navlogo" src={logo}></img></div>
                <div><button className="logout-button" onClick={() => stytch.session.revoke()}>Logout</button></div>
            </div>
            {!initialAction?
            <div className="initial">
                <div className="botdiv-holder" style={{marginBottom:"2em"}}>
                    <div id="bot" className="botdiv2 bert atrest" style={{background:"#b19dd0"}}></div>
                    <img style={{zIndex:1000000,background:'white'}} className="botdiv" src={matthew}></img>
                </div>
                <button className="button-30" onClick={()=>{setInitialAction(true)}}>Launch</button>
            </div>:
            <div className="meat-holder">
                <Left></Left>
                <Right></Right>
            </div>}
        </div>
    )
}

function clearTextInput() {
    document.getElementById("textage").value = '';
}

function Left() { //display text, handle recording?, handle multiple choice input, handle text input
    const tutor = useSelector(state => state.tutor)
    const dispatch = useDispatch();

    useEffect(()=>{ //attach and remove the keydown handler each time, why not
        LoadOrText('load')

        let obj = tutor.queue[tutor.index];
        switch(obj.left.inputType) {
            case 'reviewOrQuiz':
                break;
            case 'multipleChoice':
                break;
            case 'multipleChoiceReview':
                break;
            case 'multipleChoiceChat':
                break;
            case 'textInput':
                makeClick(answerText);
                return () => {
                    removeClick();
                };
            case 'textInputReview':
                makeClick(answerTextReview);
                return () => {
                    removeClick();
                };
            case 'chatInput':
                makeClick(answerChat);
                return () => {
                    removeClick();
                };
            case 'chatInputName':
                makeClick(answerChatName);
                return () => {
                    removeClick();
                };
            case 'chatInputInstrument':
                makeClick(answerChatInstrument);
                return () => {
                    removeClick();
                };
            case 'rightSide':
                return <></>;
            default:
                makeClick(next);
                return () => {
                    removeClick();
                };
        }
    })

    //question object
    //{
        //type: question
        //questionType: selectNoteName | writeNoteName | selectNoteStaff | writeNoteStaff

        //left: {
        // inputType: multipleChoice | textInput | rightSide
        // text: string
        // answerChoices: []
        // url: string
        //}

        //right: {
        // displayType: staff | multipleStaff
        // needsCheck: true | false
        // drawParameters: [{clef:"treble",drawType:"note|scale|interval|etc",note:"A4",interactiveType:"notedrawing"},{},...]
        //}
    //}

    const actions = () => {
        let obj = tutor.queue[tutor.index];
        if (obj.left.switchTo=="byebye") return <></>
        switch(obj.left.inputType) {
            //multiple choice, continue, text input, select
            case 'reviewOrQuiz':
                return <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'0.5em'}}>{obj.left.choices.map((item,index)=>{return <button className="button-30" key={"mcbutton"+index} onClick={()=>{reviewOrQuiz(item)}}>{item}</button>})}</div>
                break;
            case 'multipleChoice':
                return <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'0.5em'}}>{obj.left.answerChoices.map((item,index)=>{return <button className="button-30" key={"mcbutton"+index} onClick={()=>{answer(item)}}>{item}</button>})}</div>
                break;
            case 'multipleChoiceReview':
                return <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'0.5em'}}>{obj.left.answerChoices.map((item,index)=>{return <button className="button-30" key={"mcbutton"+index} onClick={()=>{answerMCReview(item)}}>{item}</button>})}</div>
                break;
            case 'multipleChoiceChat':
                return obj.left.choices.map((item,index)=>{return <button key={"mcbutton"+index} onClick={()=>{answerChoiceChat(item)}}>{item}</button>})
                break;
            case 'textInput':
                return <div>
                    <input id="textage" type="text" className="textage"/>
                    <button className="button-30" onClick={()=>{answerText()}}>Submit</button>
                </div>
            case 'textInputReview':
                return <div>
                    <input id="textage" type="text" className="textage"/>
                    <button className="button-30" onClick={()=>{answerTextReview()}}>Submit</button>
                </div>
            case 'chatInput':
                return <div>
                    <input id="textage" type="text" className="textage"/>
                    <button className="button-30" onClick={()=>{answerChat()}}>Submit</button>
                </div>
            case 'chatInputName':
                return <div>
                    <input id="textage" type="text" className="textage"/>
                    <button className="button-30" onClick={()=>{answerChatName()}}>Submit</button>
                </div>
            case 'chatInputInstrument':
                return <div>
                    <input id="textage" type="text" className="textage"/>
                    <button className="button-30" onClick={()=>{answerChatInstrument()}}>Submit</button>
                </div>
            case 'rightSide':
                return <></>;
            /*case 'startLesson':
                return <button onClick={()=>{startLesson()}}>Start</button>*/
            default:
                return <button className="button-30" onClick={()=>{next()}}>Continue</button>
        }
    }

    const next = async (sub) => {
        switch(tutor.queue[tutor.index].left.switchTo) {
            case "ending":
                getEnding();
                return;
            case "review":
                getReview();
                return;
            case "feedback":
                getFeedback();
                return;
            default:
                break;
        }
        if (tutor.queue[tutor.index].left.nextFeedback) {
            getNextFeedback();
            return;
        }
        if (tutor.queue[tutor.index].left.lastFeedback) {
            getNextReview();
            return;
        }
        if (tutor.queue[tutor.index].left.startLesson) {
            getLesson();
            return;
        }
        if (tutor.queue[tutor.index].left.startReview) {
            getReview();
            return;
        }
        if (tutor.queue[tutor.index].left.startQuiz) {
            getQuiz();
            return;
        }
        if (tutor.queue[tutor.index].left.startPractice) {
            getQuestions();
            return;
        }

        //else just increment
        dispatch(setQueueIndex(tutor.index+1))
    }

    const startLesson = () => {
        let obj = tutor.queue[tutor.index];
        dispatch(
            setPageQueue(obj.lesson)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const getLesson = async () => {
        LoadOrText('load');
        let res = await axios.get(THESERVER+'/lesson');
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const getNextFeedback = async () => {
        LoadOrText('load');
        let res = await axios.get(THESERVER+'/nextfeedback');
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const getNextReview = async () => {
        LoadOrText('load');
        let res = await axios.get(THESERVER+'/nextreview');
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const getReview = async () => {
        LoadOrText('load');
        let res = await axios.get(THESERVER+'/review');
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const getQuiz = async () => {
        LoadOrText('load');
        let res = await axios.get(THESERVER+'/quiz');
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const getFeedback = async () => {
        LoadOrText('load');
        let res = await axios.get(THESERVER+'/feedback');
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const getEnding = async () => {
        LoadOrText('load');
        let res = await axios.get(THESERVER+'/ending');
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const getQuestions = async () => {
        LoadOrText('load');
        let res = await axios.get(THESERVER+'/quiz');
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const answer = async (sub) => {
        LoadOrText('load');
        let res = await axios.post(THESERVER+'/answer',{studentID:STUDENT_ID,answer:sub});
        //put response in queue
        dispatch(
            setPageQueue(res.data.feedback)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const answerMCReview = async (sub) => {
        LoadOrText('load');
        let res = await axios.post(THESERVER+'/answerreview',{studentID:STUDENT_ID,answer:sub});
        //put response in queue
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const answerText = async () => {
        LoadOrText('load');
        let sub = document.getElementById("textage").value;
        let res = await axios.post(THESERVER+'/answer',{studentID:STUDENT_ID,answer:sub});
        clearTextInput();
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const answerTextReview = async () => {
        LoadOrText('load');
        let sub = document.getElementById("textage").value;
        let res = await axios.post(THESERVER+'/answerreview',{studentID:STUDENT_ID,answer:sub});
        clearTextInput();
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const answerChat = async () => {
        LoadOrText('load');
        let sub = document.getElementById("textage").value;
        let res = await axios.post(THESERVER+'/chat',{studentID:STUDENT_ID,message:sub});
        clearTextInput();
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const answerChatName = async () => {
        LoadOrText('load');
        let sub = document.getElementById("textage").value;
        let res = await axios.post(THESERVER+'/inputname',{studentID:STUDENT_ID,message:sub});
        clearTextInput();
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const answerChatInstrument = async () => {
        LoadOrText('load');
        let sub = document.getElementById("textage").value;
        let res = await axios.post(THESERVER+'/inputinstrument',{studentID:STUDENT_ID,message:sub});
        clearTextInput();
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const answerChoiceChat = async (sub) => {
        LoadOrText('load');
        let res = await axios.post(THESERVER+'/chat',{studentID:STUDENT_ID,message:sub});
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const reviewOrQuiz = async (sub) => {
        LoadOrText('load');
        let res = await axios.post(THESERVER+'/revieworquiz',{studentID:STUDENT_ID,message:sub});
        dispatch(
            setPageQueue(res.data)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    return (
        <div className="left-side" id="left-side">
            <div className="botdiv-holder" style={{marginBottom:"2em"}}>
                <div id="bot" className="botdiv2 bert atrest" style={{background:"#b19dd0"}}></div>
                <img style={{zIndex:1000000,background:'white'}} className="botdiv" src={matthew}></img>
            </div>
            <div style={{display:'flex',justifyContent:'center'}}><div id="the-loader" className="loader"></div></div>
            <div style={{visibility:'hidden',paddingLeft:"10px",paddingRight:"10px"}} id="the-text">
                <div className="booty-text">{tutor.queue[tutor.index].left.text}</div>
                {actions()}
            </div>
            <Matthew/>
        </div>
    )
}

function LoadOrText(tog) {
    switch(tog) {
        case 'load':
            document.getElementById("the-loader").style.display = 'flex';
            document.getElementById("the-text").style.visibility = 'hidden';
            break;
        case 'text':
            document.getElementById("the-loader").style.display = 'none';
            document.getElementById("the-text").style.visibility = 'visible';
            if (document.getElementById("textage"))
                document.getElementById("textage").focus();
            break;
    }
}

function Right() { //show staff, handle interactions with staff
    const tutor = useSelector(state => state.tutor)
    const dispatch = useDispatch();

    const answer = async (sub) => {
        let res = await axios.post(THESERVER+'/answer',{answer:sub,studentID:STUDENT_ID});
        //put response in queue
        dispatch(
            setPageQueue(res.data.feedback)
        )
        dispatch(
            setQueueIndex(0)
        )
    }

    const display = () => {
        switch(tutor.queue[tutor.index].right.displayType) {
            case "staff": //one staff
                return <div style={{width:"100%"}}><Staff answer={answer} params={tutor.queue[tutor.index].right.drawParameters[0]} question={tutor.queue[tutor.index]}/><div>{(tutor.queue[tutor.index].right.needsCheck)?<button>Check</button>:<></>}</div></div>
            case "multipleStaff": //many staff, need to click on one
                return <div style={{display:'flex',width:'100%'}}><div className="staff-holder">
                    {tutor.queue[tutor.index].right.drawParameters.map((item,index)=>{
                        return <div key={"mstaff-choice"+index} className={"div"+(index+1)} onClick={()=>{answer(item.note)}}><Staff params={item} question={tutor.queue[tutor.index]}/></div>
                    })}
                </div></div>
        }
    }
    return (
        <div className="right-side">
            {display()}
        </div>
    )
}