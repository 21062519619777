import React, { useState, useEffect } from 'react';
import { Products } from '@stytch/vanilla-js';
import { StytchPasswordReset } from '@stytch/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStytchSession } from '@stytch/react';

const STYTCH_URL = process.env.REACT_APP_URL;

const config = {
  passwordOptions: {
    loginExpirationMinutes: 30,
    loginRedirectURL: STYTCH_URL+'/authenticate',
    resetPasswordExpirationMinutes: 30,
    resetPasswordRedirectURL: STYTCH_URL+'/reset',
  },
  products: [
    Products.passwords,
  ],
};

const Reset = () => {
  const [passwordResetToken, setPasswordResetToken] = useState('');
  const { session } = useStytchSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (session) {
      // The user is authenticated. You can redirect the user to their authenticaed experience 
      navigate('/')
    } else {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      setPasswordResetToken(token ?? '');
    }
  }, [session]);

  const callbacks = {
    onEvent: ({ type, data }) => {
      switch(type) {
        case 'PASSWORD_RESET_BY_EMAIL':
          //go to authenticate page?
          setTimeout(
            function() {
              navigate('/');
            },1000
          )
          break;
        default:
          break;
      }
      console.log(type);
      console.log(data);
    },
    onError: (data) => {
      console.log(data);
    },
  };

  return (
    <div style={{paddingTop:'1em',width:'100%'}}>
      <div>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:'1em'}}>
          <div style={{fontFamily:'Courier New',fontSize:'2em'}}>Eartrainer.io</div>
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
          <StytchPasswordReset
            config={config}
            passwordResetToken={passwordResetToken}
            callbacks={callbacks}
          />
        </div>
      </div>
    </div>
  );
};

export default Reset;