import { configureStore } from '@reduxjs/toolkit';
import tutorReducer from '../slices/tutor/tutorSlice';
import matthewReducer from '../slices/matthew/matthewSlice';

const store = configureStore({
  reducer: {
    matthew: matthewReducer,
    tutor: tutorReducer,
  },
});

export default store;
