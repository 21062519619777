import Snap from 'snapsvg-cjs';

export function staff(id, parameters) {
    var self = this;
    this.id = id;

    this.numberNote = function(color,index,number) {
        if (color=='whites') {
            var rct = this[color][index];
            var bb = rct.getBBox();
            var bw = 10;
            var bx = bb.x+bb.width/2;
            var by = bb.height-bw;
            var bg = this.paper.circle(bx,by,bw);
            var txt = this.paper.text(bx,by,number);
        } else {

        }
    }

    this.highlight = function(name,tog) {
        if (name == null) return;
        var verticalIndex = octaveToI(self.clef, name);
        var staffPosition = verticalIndex * (self.spacing / 2);
        if ((verticalIndex%2)==0) { //line note
            this.whole.rect(0,staffPosition-5,parameters.width,10).attr({opacity:0.5,fill:'#E60000'});
        } else { //space note
            this.whole.rect(0,staffPosition-this.spacing/2,parameters.width,this.spacing).attr({opacity:0.5,fill:'#E60000'});
        }

        //label the note
        if (!tog) return;
        var bb = this.clefSVG.getBBox();
        var x = bb.x + bb.width;
        var w = parameters.width - x;
        var t = this.whole.text(x + w/2, staffPosition, cleanNum(name)).attr({fontSize:'18px'});
        var bbt = t.getBBox();
        t.transform('t'+(-bbt.width/2)+' '+(bbt.height/4));
        bbt = t.getBBox();
        var r = this.whole.circle(bbt.x+bbt.width/2,bbt.y+bbt.height/2,10).attr({fill:'lightgray'});
        var tg = this.whole.group(r,t);
    }

    this.handleActions = function() {
        for (var i=0; i<this.actions.length; i++) {
            if (this.actions[i].indexOf("label")!=-1&&this.actions[i]!="labelLines"&&this.actions[i]!="labelSpaces") {
                var nt = this.actions[i].split("label")[1];
                this.highlight(nt,true);
                return;
            }
            switch(this.actions[i]) {
                case "highlight":
                    this.highlight(this.actionInputs[i]);
                    break;
                case "labelLines":
                    var txt = ['E','G','B','D','F'];
                    var bb = this.clefSVG.getBBox();
                    var x = bb.x + bb.width;
                    var mrg = 10;
                    var w = parameters.width - x - 2*mrg;
                    x+=mrg;
                    var incr = w / txt.length;
                    for (var i=0; i<txt.length; i++) {
                        var t = this.whole.text(x+incr*i+incr/2,(txt.length-i-1)*this.spacing,txt[i]).attr({fontSize:'18px'})
                        var bbt = t.getBBox();
                        t.transform('t'+(-bbt.width/2)+' '+bbt.height/4);
                        bbt = t.getBBox();
                        var r = this.whole.circle(bbt.x+bbt.width/2,bbt.y+bbt.height/2,10).attr({fill:'lightgray'});
                        var tg = this.whole.group(r,t);
                    }
                    break;
                case "labelSpaces":
                    var txt = ['F','A','C','E'];
                    var bb = this.clefSVG.getBBox();
                    var x = bb.x + bb.width;
                    var mrg = 10;
                    var w = parameters.width - x - 2*mrg;
                    x+=mrg;
                    var incr = w / txt.length;
                    for (var i=0; i<txt.length; i++) {
                        var t = this.whole.text(x+incr*i+incr/2,(txt.length-i-1)*this.spacing+this.spacing/2,txt[i]).attr({fontSize:'18px'})
                        var bbt = t.getBBox();
                        t.transform('t'+(-bbt.width/2)+' '+bbt.height/4);
                        bbt = t.getBBox();
                        var r = this.whole.circle(bbt.x+bbt.width/2,bbt.y+bbt.height/2,10).attr({fill:'lightgray'});
                        var tg = this.whole.group(r,t);
                    }
                    break;
            }
        }
    }

    this.highlightKeys = function(keys) {
        for (var i=0; i<this.whites.length; i++) this.whites[i].attr({fill:'white'});
        for (var i=0; i<this.blacks.length; i++) this.blacks[i].attr({fill:'black'});

        for (var i=0; i<keys.length; i++) {
            let key = keys[i].split(':');
            window.keyboardStaff[key[0]][key[1]].attr({fill:'red'});
        }
    }

    this.countNotes = function(startIndex,endIndex) {
        for (var i=0; i<(endIndex-startIndex+1); i++) {
            var rct = this.rectByIndex(startIndex+i);
            scheduleCount(rct,i*1000);
        }
    }

    var scheduleCount = function(ele,delay) {
        return window.setTimeout(
            function() {
                ele.attr({fill:'green'});
            },delay
        )
    }

    this.rectByIndex = function(index) {
        switch(index) {
            case 0:
                return this.whites[0];
                break;
            case 1:
                return this.blacks[0];
                break;
            case 2:
                return this.whites[1];
                break;
            case 3:
                return this.blacks[1];
                break;
            case 4:
                return this.whites[2];
                break;
            case 5:
                return this.whites[3];
                break;
            case 6:
                return this.blacks[2];
                break;
            case 7:
                return this.whites[4];
                break;
            case 8:
                return this.blacks[3];
                break;
            case 9:
                return this.whites[5];
                break;
            case 10:
                return this.blacks[4];
                break;
            case 11:
                return this.whites[6];
                break;
            case 12:
                return this.whites[7];
                break;
            case 13:
                return this.blacks[5];
                break;
            case 14:
                return this.whites[8];
                break;
            case 15:
                return this.blacks[6];
                break;
            case 16:
                return this.whites[9];
                break;
        }
    }

    this.exportData = function() {
        switch(this.interactiveType) {
            case 'keysigwriting':
                var ary = [];
                for (var i=0; i<this.keySigIndices.length; i++)
                    ary.push(this.keySigIndices[i].helm);
                return ary;
            case 'scalewriting':
                var ary = [];
                for (var i=0; i<this.scaleNotes.length; i++)
                    ary.push(this.scaleNotes[i].name);
                return ary;
            case 'intervalwriting':
                if (!this.note) return [];
                return [this.note.name];
            case 'triadwriting':
                var ary = [];
                for (var i=0; i<this.chordNotes.length; i++)
                    ary.push(this.chordNotes[i].name.replace('1','').replace('2','').replace('3','').replace('4','').replace('5','').replace('6','').replace('7',''));
                ary.reverse();
                return ary;
            case 'notewriting':
                return this.note.helm;
        }
    }

    this.whites = [];
    this.blacks = [];
    this.drawKeyboard = function() {
        this.paper = Snap(this.width,500);
        document.getElementById(id).innerHTML = '';
        document.getElementById(id).appendChild(this.paper.node);
        this.staff = this.paper.group();
        this.keyboard = this.paper.group();
        //two octaves
        var ww = 45;
        var bw = 22;
        for (var i=0; i<10; i++) {
            this.whites.push(this.keyboard.rect(i*ww,0,ww,150).attr({fill:'white',stroke:'black'}));
        }
        for (var i=0; i<9; i++) {
            if (i==2||i==6) continue;
            this.blacks.push(this.keyboard.rect((i+1)*ww-bw/2,0,bw,90).attr({stroke:'black'}));
        }
    }

    //interactivity===================================================================================//
    this.interactive = function () {
        switch (this.interactiveType) {
            case 'keysigwriting':
                this.setupKeySig();
                break;
            case 'notewriting':
                this.setupNoteWriting();
                break;
            case 'scalewriting':
                this.setupScaleWriting();
                break;
            case 'intervalwriting':
                this.setupIntervalWriting();
                break;
            case 'triadwriting':
                this.setupChordWriting();
                break;
            case 'halfstepmarking':
                this.setupHalfStepMarking();
                break;
            default:
                break;
        }
    };

    //zero-opacity cover for hovering and clicking
    this.makeCover = function () {
        var bb = this.whole.getBBox();
        this.cover = this.whole
            .rect(0, -this.topOffset - this.margin, this.width, bb.height + 2 * this.margin)
            .attr({ opacity: 0 });
        //depending on type, make the click events
        //key sig writing -> left to right
        //note writing -> single
        //scale writing -> 8 or 15
        //interval writing -> single with two notes
        //chord writing -> single with three notes
    };

    //find line or space index
    this.findStaffPos = function (cp) {
        var offset = this.spacing / 2.5;
        for (var i = -100; i < 100; i++) {
            if (cp.y - offset < (i * this.spacing) / 2) return i;
        }
    };

    //key signature writing===========//
    var axeSelect = function () {
        self.axeButtonWidth = 60;
        var dy = -self.margin - self.topOffset - 30;
        var rct = self.whole.rect(0, dy, 2 * self.axeButtonWidth, 30, 5).attr({ fill: rnGrad2 });
        var ar1 = axeText(0, dy, 'sharp');
        var ar2 = axeText(self.axeButtonWidth, dy, 'flat');
        self.axeRects = [ar1, ar2];
        var axeSelectGroup = self.whole.group(rct, ar1.txt, ar1.rct, ar2.txt, ar2.rct);
        axeSelectGroup.transform('t' + (self.width / 2 - self.axeButtonWidth) + ' 0');
        axeSelectGroup.node.classList.add('berten');
    };
    var hideAxeRects = function () {
        for (var i = 0; i < self.axeRects.length; i++) {
            var txt = self.axeRects[i].txt;
            txt.attr({ fontWeight: 'normal' });
            var bb = txt.getBBox();
            txt.transform('t' + (self.axeButtonWidth / 2 - bb.width / 2) + ' ' + bb.height);
            self.axeRects[i].rct.attr({ opacity: 0 });
        }
    };
    var axeText = function (x, y, text) {
        var txt = self.whole.text(x, y + 3, text).attr({ fontFamily: 'arial' });
        var rct = self.whole.rect(x, y, self.axeButtonWidth, 30, 5).attr({ opacity: 0.1 });
        rct.node.id = 'pointer';
        rct.click(function () {
            hideAxeRects();
            self.axeSelected = text;
            rct.attr({ opacity: 0.1 });
            txt.attr({ fontWeight: 'bold' });
            var bb = txt.getBBox();
            txt.transform('t' + (self.axeButtonWidth / 2 - bb.width / 2) + ' ' + bb.height);
            removeAll();
        });
        if (text == 'flat') rct.attr({ opacity: 0 });
        if (text == 'sharp') txt.attr({ fontWeight: 'bold' });
        var bb = txt.getBBox();
        txt.transform('t' + (self.axeButtonWidth / 2 - bb.width / 2) + ' ' + bb.height);
        return { txt: txt, rct: rct };
    };
    var axeRect = function (ele, index) {
        var bb = ele.getBBox();
        var rct = self.whole.rect(bb.x, bb.y, bb.width, bb.height).attr({ opacity: 0 });
        ele.rct = rct;
        rct.node.onclick = function (e) {
            //remove accidental
            if (self.keySigIndex != index + 1) {
                alert('Please remove ' + self.axeSelected + 's from last to first!');
                return;
            }
            removeAccidental(index, e);
        };
    };
    var removeAll = function () {
        for (var i = 0; i < self.keySigIndices.length; i++) removeAccidental(i);
        self.keySigIndices = [];
        self.keySigIndex = 0;
    };
    var removeProposedAxe = function () {
        if (self.proposedAxe) self.proposedAxe.remove();
    };
    var placeProposedAxe = function (e) {
        self.proposedAxe = placeAccidental(e);
        self.proposedAxe.attr({ opacity: 0.5 });
    };
    var removeAccidental = function (index, e) {
        self.keySigIndices[index].remove();
        self.keySigIndices[index].rct.remove();
        self.keySigIndices[index] = null;
        self.keySigIndex = self.keySigIndex - 1;
        if (e) placeProposedAxe(e);
    };
    var placeAccidental = function (e) {
        removeProposedAxe();
        var cp = cursorPoint(e, self.paper, self.cover);
        var staffPosition = Math.max(self.findStaffPos(cp), -1);
        staffPosition = Math.min(staffPosition, 9);
        var name = self.noteNames[staffPosition + 6];
        var helm = self.helmNames[staffPosition + 6];
        staffPosition *= self.spacing / 2;
        let axe;
        switch (self.axeSelected) {
            case 'sharp':
                axe = self.whole
                    .path(sharpPath)
                    .transform('t' + (self.keySigIndex * 18 - 66) + ' ' + staffPosition + ' s0.062 -0.062');
                axe.name = name + '♯';
                axe.helm = helm[0] + '♯' + helm[1];
                break;
            case 'flat':
                axe = self.whole
                    .path(flatPath)
                    .transform('t' + (self.keySigIndex * 18 - 15) + ' ' + (staffPosition - 160.5) + ' s0.062 -0.062');
                axe.name = name + '\u0231';
                axe.helm = helm[0] + '\u0231' + helm[1];
                break;
            default:
                throw new Error('Unknown axe type: ' + self.axeSelected);
        }
        self.whole.prepend(axe);
        return axe;
    };
    this.setupKeySig = function () {
        this.axeSelected = 'sharp';
        axeSelect();
        this.keySigIndex = 0;
        this.keySigIndices = [];
        this.cover.node.onmousemove = function (e) {
            //show a low-opacity accidental
            placeProposedAxe(e);
        };
        this.cover.hover(
            function () {},
            function () {
                //remove the proposed accidental
                removeProposedAxe();
            },
        );
        this.cover.node.onclick = function (e) {
            var axe = placeAccidental(e);
            new axeRect(axe, self.keySigIndex);
            self.keySigIndices[self.keySigIndex] = axe;
            self.keySigIndex = self.keySigIndex + 1;
        };
    };
    //key signature writing===========//

    //note writing====================//
    var axeMenu = function (note) {
        var bb = note.getBBox();
        var xN = bb.x - 75;
        var yN = bb.y + 20;
        var vmrg = 5;
        var bw = 25;

        //var num = retNum(bb.name);
        //var base = retBase(bb.name);

        var rct = self.whole.rect(bb.x + 28, bb.y - 60, 24, 155, 5).attr({ fill: '#E0E5E6' });

        var axes = ['x', '♯', 'n', '\u0231', 'bb'];
        var axHov = function (rct, ax, i) {
            rct.node.id = 'pointer';
            var axr = self.whole.group(ax);
            var axg = self.whole.group(axr, rct);
            axg.hover(
                function () {
                    axr.transform('s1.2');
                },
                function () {
                    axr.transform('s1');
                },
            );

            axg.click(function () {
                if (note.axe) {
                    if (note.axe.type === axes[i]) {
                        removeNoteAx(note);
                        hide();
                        return;
                    }
                    removeNoteAx(note);
                }
                drawNoteAx(note, axes[i]);
                hide();
            });

            return axg;
        };

        var eles = [];

        //double sharp
        var yDraw;
        var doubleSharp = self.whole.path(doublesharpPath).transform('t' + (xN + 79) + ' ' + (yN - 373) + ' s0.2 -0.2');
        var bb1 = doubleSharp.getBBox();
        yDraw = bb1.y - vmrg;
        var dsr = self.whole
            .rect(bb1.x + bb1.width / 2 - bw / 2, yDraw, bw, bb1.height + 2 * vmrg)
            .attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(dsr, doubleSharp, 0));

        yDraw += bb1.height + 2 * vmrg;

        var sharp = self.whole.path(sharpPath).transform('t' + (xN - 22) + ' ' + (yN - 38) + ' s0.032 -0.032');
        var bb2 = sharp.getBBox();
        var sr = self.whole.rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb2.height + 2 * vmrg).attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(sr, sharp, 1));

        yDraw += bb2.height + 2 * vmrg;

        var natural = self.whole.path(naturalPath).transform('t' + (xN + 32) + ' ' + (yN - 4) + ' s0.032 -0.032');
        var bb3 = natural.getBBox();
        var nr = self.whole.rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb3.height + 2 * vmrg).attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(nr, natural, 2));

        yDraw += bb3.height + 2 * vmrg;

        var flat = self.whole.path(flatPath).transform('t' + (xN + 29) + ' ' + (yN - 122.5) + ' s0.032 -0.032');
        var bb4 = flat.getBBox();
        var fr = self.whole.rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb4.height + 2 * vmrg).attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(fr, flat, 3));

        yDraw += bb4.height + 2 * vmrg;

        var doubleFlat = self.whole.path(doubleflatPath).transform('t' + (xN + 40) + ' ' + (yN - 272) + ' s0.15 0.15');
        var bb5 = doubleFlat.getBBox();
        var dfr = self.whole
            .rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb5.height + 2 * vmrg)
            .attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(dfr, doubleFlat, 4));

        var menu = self.whole.group(rct);
        for (var z = 0; z < eles.length; z++) menu.append(eles[z]);
        self.whole.append(menu);
        menu.node.classList.add('berten');

        //transform to middle of note
        menu.transform('t12 ' + (bb.y + bb.height / 2 - (menu.getBBox().y + menu.getBBox().height / 2)));

        if (note.i < 0) moveEle(menu, 0, (-note.i * self.spacing) / 2); //if too high
        if (note.i > 8) moveEle(menu, 0, ((8 - note.i) * self.spacing) / 2); //if too low

        this.remove = function () {
            menu.remove();
        };

        this.move = function (dx) {
            moveEle(menu, dx, 0);
        };

        this.show = function () {
            menu.attr({ display: '' });
            self.axeMenuIsOpen = this;
            self.whole.append(menu);
        };
        this.hide = function () {
            menu.attr({ display: 'none' });
            self.axeMenuIsOpen = null;
        };
        var hide = this.hide;
        this.hide();
    };
    var removeNoteAx = function (note) {
        note.axe.remove();
        note.axe = null;
        note.name = note.name
            .replace('x', '')
            .replace('bb', '')
            .replace('\u0231', '')
            .replace('n', '')
            .replace('♯', '');
    };
    var drawNoteAx = function (note, type) {
        var bb = note.getBBox();
        var x = bb.x;
        var y = bb.y;
        var axe;
        switch (type) {
            case '\u0231':
                axe = self.whole.path(flatPath).transform('t' + (x - 99) + ' ' + (y - 151.625) + ' s0.062 -0.062');
                break;
            case '♯':
                axe = self.whole.path(sharpPath).transform('t' + (x - 150) + ' ' + (y + 8.875) + ' s0.062 -0.062');
                break;
            case 'n':
                axe = self.whole
                    .path(naturalPath)
                    .transform('t' + (x + 32 - 128) + ' ' + (y + 8.875) + ' s0.062 -0.062'); //was x+32
                break;
            case 'bb':
                axe = self.whole
                    .path(doubleflatPath)
                    .transform('t' + (x + 32 - 128) + ' ' + (y - 260.5 - 17.75 * 4) + ' s0.32 0.32');
                break;
            case 'x':
                axe = self.whole
                    .path(doublesharpPath)
                    .transform('t' + (x + 79 - 128) + ' ' + (y - 306.875 + 8.875) + ' s0.32 -0.32');
                break;
            default:
                return;
        }
        axe.type = type;
        note.axe = axe;
        note.name = newNoteName(note.name, type);
        self.whole.prepend(axe);
    };
    var placeProposedNote = function (e) {
        self.proposedNote = placeNote(e);
        self.proposedNote.attr({ opacity: 0.5 });
        for (var i = 0; i < self.proposedNote.ledgers.length; i++) self.proposedNote.ledgers[i].attr({ opacity: 0.5 });
    };
    var removeProposedNote = function () {
        if (self.proposedNote) {
            self.proposedNote.remove();
            for (var i = 0; i < self.proposedNote.ledgers.length; i++) self.proposedNote.ledgers[i].remove();
        }
    };
    var removeNote = function (e) {
        self.note.remove();
        for (var i = 0; i < self.note.ledgers.length; i++) self.note.ledgers[i].remove();
        self.note.rct.remove();
        self.note.axeMenu.remove();
        self.axeMenuIsOpen = null;
        if (self.note.axe) self.note.axe.remove();
        self.note = null;
        if (e) placeProposedNote(e);
    };
    var noteRect = function (ele) {
        var bb = ele.getBBox();
        var rct = self.whole.rect(bb.x, bb.y, bb.width, bb.height).attr({ opacity: 0 });
        ele.rct = rct;
        rct.node.onclick = function (e) {
            //remove note
            removeNote(e);
        };
        rct.node.oncontextmenu = function (e) {
            e.preventDefault();
            ele.axeMenu.show();
        };
    };
    var placeNote = function (e) {
        removeProposedNote();
        var cp = cursorPoint(e, self.paper, self.cover);
        var staffPosition = Math.max(self.findStaffPos(cp), -6);
        staffPosition = Math.min(staffPosition, 14);
        var name = self.noteNames[staffPosition + 6];
        var helm = self.helmNames[staffPosition + 6];
        var verticalIndex = staffPosition;
        staffPosition *= self.spacing / 2;
        var note = self.whole
            .path(wholePath)
            .transform('t' + (self.width / 2 - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
        note.name = name;
        note.helm = helm;
        note.i = verticalIndex;
        self.whole.prepend(note);
        drawLedgers(note, staffPosition);
        return note;
    };

    //feedback===========================================//
    this.wrongNote = function () {
        this.note.attr({ fill: '#E60000' });
    };
    this.correctNote = function () {
        this.note.attr({ fill: '#71C562' });
    };
    this.wrongScaleNote = function (i) {
        this.scaleNotes[i].attr({ fill: '#E60000' });
    };
    this.correctScale = function () {
        for (var i = 0; i < this.scaleNotes.length; i++) {
            this.scaleNotes[i].attr({ fill: '#71C562' });
        }
    };
    this.wrongSigEntry = function (i) {
        this.keySigIndices[i].attr({ fill: '#E60000' });
    };
    this.correctSig = function () {
        for (var i = 0; i < this.keySigIndices.length; i++) {
            this.keySigIndices[i].attr({ fill: '#71C562' });
        }
    };
    this.wrongChordNote = function (i) {
        this.chordNotes[i].attr({ fill: '#E60000' });
    };
    this.correctChord = function () {
        for (var i = 0; i < this.chordNotes.length; i++) {
            this.chordNotes[i].attr({ fill: '#71C562' });
        }
    };
    //feedback===========================================//

    this.setupNoteWriting = function () {
        this.cover.node.onmousemove = function (e) {
            //show a low-opacity notehead
            if (self.note) return;
            placeProposedNote(e);
        };
        this.cover.hover(
            function () {},
            function () {
                //remove the proposed accidental
                removeProposedNote();
            },
        );
        this.cover.node.onclick = function (e) {
            if (self.axeMenuIsOpen) {
                self.axeMenuIsOpen.hide();
                self.axeMenuIsOpen = null;
                return;
            }
            if (self.note) return;
            var note = placeNote(e);
            self.note = note;
            new noteRect(note);
            note.axeMenu = new axeMenu(note);
            //self.keySigIndices[self.keySigIndex] = axe;
            //self.keySigIndex = self.keySigIndex + 1;
        };
    };
    //note writing====================//

    //scale writing===================//
    var getC = function (cp) {
        var x = cp.x;
        var nd = 0;
        for (var i = 0; i < self.cMouseXs.length; i++) {
            if (x > self.cMouseXs[i]) nd = i;
        }
        return nd;
    };
    var getCH = function (cp) {
        var x = cp.x;
        var nd = 0;
        for (var i = 0; i < self.nDrawXs.length; i++) {
            if (x > self.nDrawXs[i]) nd = i;
        }
        return nd;
    };
    var scaleNoteRect = function (ele) {
        var bb = ele.getBBox();
        var rct = self.whole.rect(bb.x, bb.y, bb.width, bb.height).attr({ opacity: 0 });
        ele.rct = rct;
        rct.node.onclick = function (e) {
            //remove note
            removeScaleNote(e, ele.xPosition);
        };
        rct.node.oncontextmenu = function (e) {
            e.preventDefault();
            ele.axeMenu.show();
        };
    };
    var placeProposedScaleNote = function (e) {
        self.proposedScaleNote = placeScaleNote(e);
        self.proposedScaleNote.attr({ opacity: 0.5 });
        for (var i = 0; i < self.proposedScaleNote.ledgers.length; i++)
            self.proposedScaleNote.ledgers[i].attr({ opacity: 0.5 });
    };
    var removeProposedScaleNote = function () {
        if (self.proposedScaleNote) {
            self.proposedScaleNote.remove();
            for (var i = 0; i < self.proposedScaleNote.ledgers.length; i++) self.proposedScaleNote.ledgers[i].remove();
        }
    };
    var removeScaleNote = function (e, index) {
        self.scaleNotes[index].remove();
        for (var i = 0; i < self.scaleNotes[index].ledgers.length; i++) self.scaleNotes[index].ledgers[i].remove();
        self.scaleNotes[index].rct.remove();
        self.scaleNotes[index].axeMenu.remove();
        self.axeMenuIsOpen = null;
        if (self.scaleNotes[index].axe) self.scaleNotes[index].axe.remove();
        self.scaleNotes[index] = null;
        if (e) placeProposedScaleNote(e);
    };
    var placeScaleNote = function (e) {
        removeProposedScaleNote();
        var cp = cursorPoint(e, self.paper, self.cover);
        var staffPosition = Math.max(self.findStaffPos(cp), -6);
        var xPosition = getC(cp);
        staffPosition = Math.min(staffPosition, 14);
        var name = self.noteNames[staffPosition + 6];
        var verticalIndex = staffPosition;
        staffPosition *= self.spacing / 2;
        var note = self.whole
            .path(wholePath)
            .transform('t' + (self.nDrawXs[xPosition] - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
        moveEle(note, -note.getBBox().width / 2, 0);
        note.name = name;
        note.i = verticalIndex;
        note.xPosition = xPosition;
        self.whole.prepend(note);
        drawLedgers(note, staffPosition);
        return note;
    };
    this.setupScaleWriting = function () {
        //make divisions
        this.divisions = 8;
        this.scaleNotes = [];
        this.nDrawXs = [];
        this.cMouseXs = [0];
        var rmrg = 10; //calculated above
        var leftMargin = 50;
        var sx = this.width - rmrg - leftMargin;
        var division = sx / this.divisions;
        for (var i = 0; i < this.divisions; i++) {
            this.nDrawXs.push(leftMargin + (i + 1 / 2) * division);
            this.cMouseXs.push(leftMargin + (i + 1) * division);
            //self.whole.rect(this.cMouseXs[i],0,1,40);
        }
        this.cMouseXs.splice(this.divisions, 1);

        this.cover.node.onmousemove = function (e) {
            //show a low-opacity notehead
            var cp = cursorPoint(e, self.paper, self.cover);
            var xPosition = getC(cp);
            if (self.scaleNotes[xPosition]) return;
            placeProposedScaleNote(e);
        };
        this.cover.hover(
            function () {},
            function () {
                //remove the proposed accidental
                removeProposedScaleNote();
            },
        );
        this.cover.node.onclick = function (e) {
            if (self.axeMenuIsOpen) {
                self.axeMenuIsOpen.hide();
                self.axeMenuIsOpen = null;
                return;
            }
            var cp = cursorPoint(e, self.paper, self.cover);
            var xPosition = getC(cp);
            if (self.scaleNotes[xPosition]) return;
            var note = placeScaleNote(e);
            new scaleNoteRect(note);
            note.axeMenu = new axeMenu(note);
            self.scaleNotes[xPosition] = note;
        };
    };
    //scale writing===================//

    //interval writing================//
    var intervalAxeMenu = function (note) {
        var bb = note.getBBox();
        var xN = bb.x - 75;
        var yN = bb.y + 20;
        var vmrg = 5;
        var bw = 25;

        //var num = retNum(bb.name);
        //var base = retBase(bb.name);

        var rct = self.whole.rect(bb.x + 28, bb.y - 60, 24, 155, 5).attr({ fill: '#E0E5E6' });

        var axes = ['x', '♯', 'n', '\u0231', 'bb'];
        var axHov = function (rct, ax, i) {
            rct.node.id = 'pointer';
            var axr = self.whole.group(ax);
            var axg = self.whole.group(axr, rct);
            axg.hover(
                function () {
                    axr.transform('s1.2');
                },
                function () {
                    axr.transform('s1');
                },
            );

            axg.click(function () {
                if (note.axe) {
                    if (note.axe.type == axes[i]) {
                        removeNoteAx(note);
                        returnHandledAx();
                        secondsAxe(note);
                        handleIntervalAx(note);
                        hide();
                        return;
                    }
                    removeNoteAx(note);
                }
                drawNoteAx(note, axes[i]);
                returnHandledAx();
                secondsAxe(note);
                handleIntervalAx(note);
                hide();
            });

            return axg;
        };

        var eles = [];

        //double sharp
        var yDraw;
        var doubleSharp = self.whole.path(doublesharpPath).transform('t' + (xN + 79) + ' ' + (yN - 373) + ' s0.2 -0.2');
        var bb1 = doubleSharp.getBBox();
        yDraw = bb1.y - vmrg;
        var dsr = self.whole
            .rect(bb1.x + bb1.width / 2 - bw / 2, yDraw, bw, bb1.height + 2 * vmrg)
            .attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(dsr, doubleSharp, 0));

        yDraw += bb1.height + 2 * vmrg;

        var sharp = self.whole.path(sharpPath).transform('t' + (xN - 22) + ' ' + (yN - 38) + ' s0.032 -0.032');
        var bb2 = sharp.getBBox();
        var sr = self.whole.rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb2.height + 2 * vmrg).attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(sr, sharp, 1));

        yDraw += bb2.height + 2 * vmrg;

        var natural = self.whole.path(naturalPath).transform('t' + (xN + 32) + ' ' + (yN - 4) + ' s0.032 -0.032');
        var bb3 = natural.getBBox();
        var nr = self.whole.rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb3.height + 2 * vmrg).attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(nr, natural, 2));

        yDraw += bb3.height + 2 * vmrg;

        var flat = self.whole.path(flatPath).transform('t' + (xN + 29) + ' ' + (yN - 122.5) + ' s0.032 -0.032');
        var bb4 = flat.getBBox();
        var fr = self.whole.rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb4.height + 2 * vmrg).attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(fr, flat, 3));

        yDraw += bb4.height + 2 * vmrg;

        var doubleFlat = self.whole.path(doubleflatPath).transform('t' + (xN + 40) + ' ' + (yN - 272) + ' s0.15 0.15');
        var bb5 = doubleFlat.getBBox();
        var dfr = self.whole
            .rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb5.height + 2 * vmrg)
            .attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(dfr, doubleFlat, 4));

        var menu = self.whole.group(rct);
        for (var z = 0; z < eles.length; z++) menu.append(eles[z]);
        self.whole.append(menu);
        menu.node.classList.add('berten');

        //transform to middle of note
        menu.transform('t12 ' + (bb.y + bb.height / 2 - (menu.getBBox().y + menu.getBBox().height / 2)));

        if (note.i < 0) moveEle(menu, 0, (-note.i * self.spacing) / 2); //if too high
        if (note.i > 8) moveEle(menu, 0, ((8 - note.i) * self.spacing) / 2); //if too low

        this.remove = function () {
            menu.remove();
        };

        this.move = function (dx) {
            moveEle(menu, dx, 0);
        };

        this.show = function () {
            menu.attr({ display: '' });
            self.axeMenuIsOpen = this;
            self.whole.append(menu);
        };
        this.hide = function () {
            menu.attr({ display: 'none' });
            self.axeMenuIsOpen = null;
        };
        var hide = this.hide;
        this.hide();
    };
    var placeProposedIntervalNote = function (e) {
        self.proposedIntervalNote = placeIntervalNote(e);
        self.proposedIntervalNote.attr({ opacity: 0.5 });
        for (var i = 0; i < self.proposedIntervalNote.ledgers.length; i++)
            self.proposedIntervalNote.ledgers[i].attr({ opacity: 0.5 });
    };
    var removeProposedIntervalNote = function () {
        if (self.proposedIntervalNote) {
            self.proposedIntervalNote.remove();
            for (var i = 0; i < self.proposedIntervalNote.ledgers.length; i++)
                self.proposedIntervalNote.ledgers[i].remove();
        }
    };
    var secondsAxe = function (note) {
        if (!note.axe) return;
        if (note.beside) {
            moveEle(note.axe, -30, 0);
        }
        if (note.above) {
            moveEle(note.axe, -25, 0);
        }
    };
    var returnHandledAx = function () {
        if (self.givenNote.axe) {
            if (self.givenNote.axe.dx) {
                moveEle(self.givenNote.axe, self.givenNote.axe.dx, 0);
                delete self.givenNote.axe.dx;
            }
        }
    };
    var handleIntervalAx = function (note) {
        if (!self.givenNote) return;
        if (!self.givenNote.axe) return;
        if (!note.axe) return;
        var n1 = note;
        var n2 = self.givenNote;
        var pn1 = note;
        var pn2 = self.givenNote;
        if (pn1.i < pn2.i) {
            n1 = self.givenNote;
            n2 = note;
        }
        var bb1 = n1.axe.getBBox();
        var bb2 = n2.axe.getBBox();
        var tol = 5;
        var extra = 2;
        var dx = -(3 + n2.axe.getBBox().width + extra);
        if (bb1.y < bb2.y + bb2.height + tol) {
            moveEle(n1.axe, dx, 0);
            n1.axe.dx = -dx;
        }
    };
    var handleDrawnIntervalAx = function (n1, n2) {
        if (!n1.axe) return;
        if (!n2.axe) return;
        var pn1 = n1;
        var pn2 = n2;
        if (pn1.i < pn2.i) {
            n1 = pn2;
            n2 = pn1;
        }
        var bb1 = n1.axe.getBBox();
        var bb2 = n2.axe.getBBox();
        var tol = 5;
        var extra = 2;
        var dx = -(3 + n2.axe.getBBox().width + extra);
        if (bb1.y < bb2.y + bb2.height + tol) {
            moveEle(n1.axe, dx, 0);
            n1.axe.dx = -dx;
        }
    };
    var removeIntervalNote = function (e) {
        self.note.remove();
        for (var i = 0; i < self.note.ledgers.length; i++) self.note.ledgers[i].remove();
        self.note.rct.remove();
        self.note.axeMenu.remove();
        self.axeMenuIsOpen = null;
        if (self.note.axe) self.note.axe.remove();
        self.note = null;
        returnHandledAx();
        if (e) placeProposedIntervalNote(e);
    };
    var intervalNoteRect = function (ele) {
        var bb = ele.getBBox();
        var rct = self.whole.rect(bb.x, bb.y, bb.width, bb.height).attr({ opacity: 0 });
        ele.rct = rct;
        rct.node.onclick = function (e) {
            //remove note
            removeIntervalNote(e);
        };
        rct.node.oncontextmenu = function (e) {
            e.preventDefault();
            ele.axeMenu.show();
        };
    };
    var handleIntervalConflict = function (note) {
        if (note.i - self.givenNote.i == 1) {
            //proposed note is UNDER given note
            //move given note to the right
            var tx = 12.5;
            moveEle(self.givenNote, tx, 0);
            self.givenNote.tx = tx;
            if (self.givenNote.axe) {
                moveEle(self.givenNote.axe, -tx, 0);
                self.givenNote.axe.tx = tx;
            }
            for (var i = 0; i < self.givenNote.ledgers.length; i++) {
                moveEle(self.givenNote.ledgers[i], tx, 0);
            }

            //move proposed note
            moveEle(note, -tx, 0);

            note.under = true;
        }
        if (note.i - self.givenNote.i == -1) {
            //proposed note is ABOVE given note
            //move given note to the left
            var tx = -12.5;
            moveEle(self.givenNote, tx, 0);
            self.givenNote.tx = tx;
            if (self.givenNote.axe) {
                moveEle(self.givenNote.axe, tx, 0);
                self.givenNote.axe.tx = -tx;
            }
            for (var i = 0; i < self.givenNote.ledgers.length; i++) {
                moveEle(self.givenNote.ledgers[i], tx, 0);
            }

            moveEle(note, -tx, 0);

            note.above = true;
        }
        if (note.i == self.givenNote.i) {
            //proposed note is EQUAL to given note
            var tx = -15;
            moveEle(self.givenNote, tx, 0);
            self.givenNote.tx = tx;
            if (self.givenNote.axe) {
                moveEle(self.givenNote.axe, tx, 0);
                self.givenNote.axe.tx = -tx;
            }
            for (var i = 0; i < self.givenNote.ledgers.length; i++) {
                moveEle(self.givenNote.ledgers[i], tx, 0);
            }

            moveEle(note, -tx, 0);

            note.beside = true;
        }
    };
    var placeIntervalNote = function (e) {
        removeProposedIntervalNote();
        returnGivenNote();
        var cp = cursorPoint(e, self.paper, self.cover);
        var staffPosition = Math.max(self.findStaffPos(cp), -6);
        staffPosition = Math.min(staffPosition, 14);
        var name = self.noteNames[staffPosition + 6];
        var verticalIndex = staffPosition;
        staffPosition *= self.spacing / 2;
        var note = self.whole
            .path(wholePath)
            .transform('t' + (self.width / 2 - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
        note.name = name + ItoOctave(self.clef, verticalIndex);
        note.i = verticalIndex;
        self.whole.prepend(note);
        handleIntervalConflict(note);
        drawLedgers(note, staffPosition);
        return note;
    };
    this.setupIntervalWriting = function () {
        drawGivenNote(this.givenNote);
        this.cover.node.onmousemove = function (e) {
            //show a low-opacity notehead
            if (self.note) return;
            placeProposedIntervalNote(e);
        };
        this.cover.hover(
            function () {},
            function () {
                //remove the proposed accidental
                removeProposedIntervalNote();
            },
        );
        this.cover.node.onclick = function (e) {
            if (self.axeMenuIsOpen) {
                self.axeMenuIsOpen.hide();
                self.axeMenuIsOpen = null;
                return;
            }
            if (self.note) return;
            var note = placeIntervalNote(e);
            self.note = note;
            new intervalNoteRect(note);
            note.axeMenu = new intervalAxeMenu(note);
        };
    };
    //interval writing================//

    //chord writing===================//
    var chordAxeMenu = function (note) {
        var bb = note.getBBox();
        var xN = bb.x - 75;
        var yN = bb.y + 20;
        var vmrg = 5;
        var bw = 25;

        //var num = retNum(bb.name);
        //var base = retBase(bb.name);

        var rct = self.whole.rect(bb.x + 28, bb.y - 60, 24, 155, 5).attr({ fill: '#E0E5E6' });

        var axes = ['x', '♯', 'n', '\u0231', 'bb'];
        var axHov = function (rct, ax, i) {
            rct.node.id = 'pointer';
            var axr = self.whole.group(ax);
            var axg = self.whole.group(axr, rct);
            axg.hover(
                function () {
                    axr.transform('s1.2');
                },
                function () {
                    axr.transform('s1');
                },
            );

            axg.click(function () {
                if (note.axe) {
                    if (note.axe.type == axes[i]) {
                        removeNoteAx(note);
                        handleChordAccidentals();
                        hide();
                        return;
                    }
                    removeNoteAx(note);
                }
                drawNoteAx(note, axes[i]);
                handleChordAccidentals();
                hide();
            });

            return axg;
        };

        var eles = [];

        //double sharp
        var yDraw;
        var doubleSharp = self.whole.path(doublesharpPath).transform('t' + (xN + 79) + ' ' + (yN - 373) + ' s0.2 -0.2');
        var bb1 = doubleSharp.getBBox();
        yDraw = bb1.y - vmrg;
        var dsr = self.whole
            .rect(bb1.x + bb1.width / 2 - bw / 2, yDraw, bw, bb1.height + 2 * vmrg)
            .attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(dsr, doubleSharp, 0));

        yDraw += bb1.height + 2 * vmrg;

        var sharp = self.whole.path(sharpPath).transform('t' + (xN - 22) + ' ' + (yN - 38) + ' s0.032 -0.032');
        var bb2 = sharp.getBBox();
        var sr = self.whole.rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb2.height + 2 * vmrg).attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(sr, sharp, 1));

        yDraw += bb2.height + 2 * vmrg;

        var natural = self.whole.path(naturalPath).transform('t' + (xN + 32) + ' ' + (yN - 4) + ' s0.032 -0.032');
        var bb3 = natural.getBBox();
        var nr = self.whole.rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb3.height + 2 * vmrg).attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(nr, natural, 2));

        yDraw += bb3.height + 2 * vmrg;

        var flat = self.whole.path(flatPath).transform('t' + (xN + 29) + ' ' + (yN - 122.5) + ' s0.032 -0.032');
        var bb4 = flat.getBBox();
        var fr = self.whole.rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb4.height + 2 * vmrg).attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(fr, flat, 3));

        yDraw += bb4.height + 2 * vmrg;

        var doubleFlat = self.whole.path(doubleflatPath).transform('t' + (xN + 40) + ' ' + (yN - 272) + ' s0.15 0.15');
        var bb5 = doubleFlat.getBBox();
        var dfr = self.whole
            .rect(bb2.x + bb2.width / 2 - bw / 2, yDraw, bw, bb5.height + 2 * vmrg)
            .attr({ opacity: 0 }); //,stroke:'black',fill:'none'});
        eles.push(axHov(dfr, doubleFlat, 4));

        var menu = self.whole.group(rct);
        for (var z = 0; z < eles.length; z++) menu.append(eles[z]);
        self.whole.append(menu);
        menu.node.classList.add('berten');

        //transform to middle of note
        menu.transform('t12 ' + (bb.y + bb.height / 2 - (menu.getBBox().y + menu.getBBox().height / 2)));

        if (note.i < 0) moveEle(menu, 0, (-note.i * self.spacing) / 2); //if too high
        if (note.i > 8) moveEle(menu, 0, ((8 - note.i) * self.spacing) / 2); //if too low

        this.remove = function () {
            menu.remove();
        };

        this.move = function (dx) {
            moveEle(menu, dx, 0);
        };

        this.show = function () {
            menu.attr({ display: '' });
            self.axeMenuIsOpen = this;
            self.whole.append(menu);
        };
        this.hide = function () {
            menu.attr({ display: 'none' });
            self.axeMenuIsOpen = null;
        };
        var hide = this.hide;
        this.hide();
    };
    var chordNoteRect = function (ele) {
        var bb = ele.getBBox();
        var rct = self.whole.rect(bb.x, bb.y, bb.width, bb.height).attr({ opacity: 0 });
        ele.rct = rct;
        rct.node.onclick = function (e) {
            //remove note
            removeChordNote(ele.noteID, e);
        };
        rct.node.oncontextmenu = function (e) {
            e.preventDefault();
            ele.axeMenu.show();
        };
    };
    var placeProposedChordNote = function (e) {
        self.proposedChordNote = placeChordNote(e);
        self.proposedChordNote.attr({ opacity: 0.5 });
        for (var i = 0; i < self.proposedChordNote.ledgers.length; i++)
            self.proposedChordNote.ledgers[i].attr({ opacity: 0.5 });
    };
    var removeProposedChordNote = function () {
        if (self.proposedChordNote) {
            self.proposedChordNote.remove();
            for (var i = 0; i < self.proposedChordNote.ledgers.length; i++) self.proposedChordNote.ledgers[i].remove();
        }
    };
    var removeChordNote = function (noteID, e) {
        var note;
        var noteIndex;
        for (var i = 0; i < self.chordNotes.length; i++) {
            if (self.chordNotes[i].noteID == noteID) {
                note = self.chordNotes[i];
                noteIndex = i;
            }
        }

        note.remove();
        for (var i = 0; i < note.ledgers.length; i++) note.ledgers[i].remove();
        note.rct.remove();
        note.axeMenu.remove();
        self.axeMenuIsOpen = null;
        if (note.axe) note.axe.remove();
        self.chordNotes.splice(noteIndex, 1);
        //calcStemDirection();
        //returnChordNotes();
        //handleChordSeconds(self.chordNotes);
        handleChordAccidentals();
        if (e) placeProposedChordNote(e);
    };
    var placeChordNote = function (e) {
        removeProposedChordNote();
        var cp = cursorPoint(e, self.paper, self.cover);
        var staffPosition = Math.max(self.findStaffPos(cp), -6);
        staffPosition = Math.min(staffPosition, 14);
        var name = self.noteNames[staffPosition + 6];
        var verticalIndex = staffPosition;
        staffPosition *= self.spacing / 2;
        var note = self.whole
            .path(wholePath)
            .transform('t' + (self.width / 2 - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
        note.name = name + ItoOctave(self.clef, verticalIndex);
        note.i = verticalIndex;
        self.whole.prepend(note);
        drawLedgers(note, staffPosition);
        return note;
    };
    var calcStemDirection = function () {
        var max = -1000000;
        var nt;
        var index = -1;
        for (var i = 0; i < self.chordNotes.length; i++) {
            nt = self.chordNotes[i];
            if (Math.abs(nt.i - 4) > max) {
                max = Math.abs(nt.i - 4);
                index = i;
            }
        }
        if (index == -1) return;
        if (self.chordNotes[index].i <= 4) {
            self.stemDirection = 'Down';
        } else {
            self.stemDirection = 'Up';
        }
    };
    var handleChordAccidentals = function () {
        var notes = copyAry(self.chordNotes);
        notes.sort(function (a, b) {
            return a.i - b.i;
        });

        //return accidentals
        for (var i = 0; i < notes.length; i++) {
            if (notes[i].axe) {
                if (notes[i].axe.moved) {
                    moveEle(notes[i].axe, notes[i].axe.movedDistance, 0);
                    delete notes[i].axe.moved;
                    delete notes[i].axe.movedDistance;
                }
                delete notes[i].axe.partners;
                delete notes[i].axe.partner;
            }
        }

        var axObj = {};
        var axI = 0;
        for (var i = 0; i < notes.length; i++) {
            if (notes[i].axe) {
                axObj[axI] = notes[i].axe;
                axI++;
            }
        }
        if (axI == 2) {
            var a1 = axObj[0];
            var a2 = axObj[1];
            var axeTrans = a1.getBBox().width + 3;
            if (axeCollide(a1, a2)) {
                moveEle(a2, -axeTrans, 0);
                a2.movedDistance = axeTrans;
                a2.moved = true;
            }
        }
        if (axI == 3) {
            var a1 = axObj[0];
            var a2 = axObj[1];
            var a3 = axObj[2];
            var axeTrans = a1.getBBox().width + 3;
            if (axeCollide(a1, a2)) {
                moveEle(a2, -axeTrans, 0);
                a2.movedDistance = axeTrans;
                a2.moved = true;
            }
            if (axeCollide(a1, a3)) {
                moveEle(a3, -axeTrans, 0);
                a3.movedDistance = axeTrans;
                a3.moved = true;
            }

            var axeTrans = a2.getBBox().width + 3;
            if (axeCollide(a2, a3)) {
                moveEle(a3, -axeTrans, 0);
                if (a3.moved) a3.movedDistance = a3.movedDistance + axeTrans;
                else a3.movedDistance = axeTrans;
                a3.moved = true;
            }
        }
    };
    var axeCollide = function (a1, a2) {
        var bb1 = a1.getBBox();
        var bb2 = a2.getBBox();
        if (
            bb2.y < bb1.y + bb1.height &&
            ((bb1.x >= bb2.x && bb1.x <= bb2.x + bb2.width) || (bb2.x >= bb1.x && bb2.x <= bb1.x + bb1.width))
        )
            return true;
        return false;
    };
    /*var handleChordSeconds = function(ary) {
		var notes = copyAry(ary);
		notes.sort(function(a,b){return b.i-a.i});
		//look through array
		if (self.stemDirection=='Up') {
			var n1, n2;
			for (var i=0; i<notes.length-1; i++) {
				n1 = notes[i];
				n2 = notes[i+1];
				if (((n2.i-n1.i) == -1) && !n1.moved) {
					moveEle(n2,25,0);
                    moveEle(n2.rct,25,0);
					n2.moved = true;
					n2.movedDistance = 25;
				}
			}
		} else {
			var n1, n2;
			for (var i=notes.length-1; i>0; i--) {
				n1 = notes[i];
				n2 = notes[i-1];
				if (((n2.i-n1.i) == 1) && !n1.moved) {
					moveEle(n2,-25,0);
                    moveEle(n2.rct,-25,0);
					n2.moved = true;
					n2.movedDistance = -25;
				}
			}
		}
	}*/
    var moveNote = function (note, dx) {
        moveEle(note, dx, 0);
        moveEle(note.rct, dx, 0);
        note.moved = true;
        note.movedDistance = dx;
        /*if (note.ledger) {
			var w = parseFloat(note.ledger.attr('width'));
			w += dx;
			note.ledger.attr({width:w});
			if (dx<0) {
				note.ledger.transform('t'+dx+' 0');
			}
		}
		if (note.ledger2) {
			var w = parseFloat(note.ledger2.attr('width'));
			w += dx;
			note.ledger2.attr({width:w});
			if (dx<0) {
				note.ledger2.transform('t'+dx+' 0');
			}
		}*/
    };
    var handleChordSeconds = function (ary) {
        var notes = copyAry(ary);
        notes.sort(function (a, b) {
            return b.i - a.i;
        });
        //look through array
        if (self.stemDirection == 'Up') {
            var n1, n2;
            for (var i = 0; i < notes.length - 1; i++) {
                n1 = notes[i];
                n2 = notes[i + 1];
                if (n2.i - n1.i == -1 && !n1.moved) {
                    moveNote(n2, 25);
                }
            }
        } else {
            var n1, n2;
            for (var i = notes.length - 1; i > 0; i--) {
                n1 = notes[i];
                n2 = notes[i - 1];
                if (n2.i - n1.i == 1 && !n1.moved) {
                    moveNote(n2, -25);
                }
            }
        }
    };
    var returnChordNotes = function () {
        var notes = self.chordNotes;
        for (var i = 0; i < notes.length; i++) {
            var nt = notes[i];
            if (nt.moved) {
                moveEle(nt, -nt.movedDistance, 0);
                moveEle(nt.rct, -nt.movedDistance, 0);
                if (nt.ledger) {
                    var w = parseFloat(nt.ledger.attr('width'));
                    w -= nt.movedDistance;
                    nt.ledger.attr({ width: w });
                    nt.ledger.transform('t0 0');
                }
                if (nt.ledger2) {
                    var w = parseFloat(nt.ledger2.attr('width'));
                    w -= nt.movedDistance;
                    nt.ledger2.attr({ width: w });
                    nt.ledger2.transform('t0 0');
                }
                delete nt.moved;
                delete nt.movedDistance;
            }
            /*if (nt.ag.moved) {
				moveEle(nt.ag,-nt.ag.movedDistance,0);
				delete nt.ag.moved;
				delete nt.ag.movedDistance;
			}*/
        }
    };
    this.setupChordWriting = function () {
        this.chordNotes = [];
        if (this.givenChordNote) {
            drawGivenChordNote();
        }
        this.cover.node.onmousemove = function (e) {
            //show a low-opacity notehead
            if (self.chordNotes.length == self.noteLimit) return;
            placeProposedChordNote(e);
        };
        this.cover.hover(
            function () {},
            function () {
                //remove the proposed accidental
                removeProposedChordNote();
            },
        );
        this.cover.node.onclick = function (e) {
            if (self.chordNotes.length == self.noteLimit) return;
            if (self.axeMenuIsOpen) {
                self.axeMenuIsOpen.hide();
                self.axeMenuIsOpen = null;
                return;
            }
            var note = placeChordNote(e);
            if (self.givenChordNote) {
                if (note.i == self.givenChordNote.i) {
                    removeProposedChordNote();
                    return;
                }
            }
            new chordNoteRect(note);
            note.axeMenu = new chordAxeMenu(note);

            //put into array
            note.noteID = 'note' + Math.random();
            self.chordNotes.push(note);
            self.chordNotes.sort(function (a, b) {
                return a.i - b.i;
            });

            handleChordAccidentals();
            //handle chord seconds
            //calcStemDirection();
            //returnChordNotes();
            //handleChordSeconds(self.chordNotes);
        };
    };
    //chord writing===================//

    //half step marking===============//
    var placeHalfStep = function (e) {
        removeProposedHalfStep();
        var cp = cursorPoint(e, self.paper, self.cover);
        var xPosition = getCH(cp);
        if (!self.nDrawXs[xPosition + 1]) return false;
        var x = (self.nDrawXs[xPosition] + self.nDrawXs[xPosition + 1]) / 2;
        var y = self.halfStepYs[xPosition];
        var step = self.whole.text(x, y, 'V').attr({ fontFamily: 'arial', fontSize: '28px' });
        step.xPosition = xPosition;
        self.whole.prepend(step);
        return step;
    };
    var removeHalfStep = function (e, index) {
        self.halfSteps[index].remove();
        self.halfSteps[index].rct.remove();
        self.halfSteps[index] = null;
        if (e) placeProposedHalfStep(e);
    };
    var halfStepRect = function (ele) {
        var bb = ele.getBBox();
        var rct = self.whole.rect(bb.x, bb.y, bb.width, bb.height).attr({ opacity: 0 });
        ele.rct = rct;
        rct.node.onclick = function (e) {
            //remove half step
            removeHalfStep(e, ele.xPosition);
        };
    };
    var placeProposedHalfStep = function (e) {
        self.proposedHalfStep = placeHalfStep(e);
        if (!self.proposedHalfStep) return;
        self.proposedHalfStep.attr({ opacity: 0.5 });
    };
    var removeProposedHalfStep = function () {
        if (self.proposedHalfStep) {
            self.proposedHalfStep.remove();
        }
    };
    this.setupHalfStepMarking = function () {
        this.halfSteps = [];
        this.drawScale(this.scale);
        getHalfStepYs();
        this.cover.node.onmousemove = function (e) {
            //show a low-opacity V
            placeProposedHalfStep(e);
        };
        this.cover.hover(
            function () {},
            function () {
                //remove the proposed V
                removeProposedHalfStep();
            },
        );
        this.cover.node.onclick = function (e) {
            var cp = cursorPoint(e, self.paper, self.cover);
            var xPosition = getCH(cp);
            if (self.halfSteps[xPosition]) return;
            var step = placeHalfStep(e);
            if (!step) return;
            new halfStepRect(step);
            self.halfSteps[xPosition] = step;
        };
    };
    //half step marking===============//

    //shared interactive functions====//
    var getHalfStepYs = function () {
        self.halfStepYs = [];
        var bb1, bb2;
        for (var i = 0; i < self.scaleNotes.length - 1; i++) {
            bb1 = self.scaleNotes[i].getBBox();
            bb2 = self.scaleNotes[i + 1].getBBox();
            self.halfStepYs.push((bb1.y + bb2.y) / 2);
        }
    };
    var drawScale = function (ary) {
        self.scaleNotes = [];
        self.nDrawXs = [];
        var rmrg = 10;
        var leftMargin = 50 + self.keySigMargin;
        var sx = self.width - rmrg - leftMargin;
        var division = sx / ary.length;
        var drawX;
        for (var i = 0; i < ary.length; i++) {
            drawX = leftMargin + (i + 1 / 2) * division;
            drawScaleNote(ary[i], drawX);
            self.nDrawXs.push(drawX);
        }
    };
    this.drawScale = drawScale;
    var newNoteName = function (name, type) {
        var last = name[name.length - 1];
        if (!isNaN(last)) {
            //has number
            name = name.slice(0, name.length - 1) + type + last;
        } else {
            name = name + type;
        }
        return name;
    };
    var needsAx = function (name) {
        if (!name) return false;
        var ary = ['x', '♯', 'n', '\u0231', 'bb'];
        for (var i = 0; i < ary.length; i++) {
            if (name.indexOf(ary[i]) != -1) return ary[i];
        }
        return false;
    };
    var needsScaleAx = function (name) {
        if (!name) return false;
        for (var i = 0; i < 9; i++) name = name.replace(i, '');

        //has ax in name
        var ary = ['x', '♯', '\u0231', 'bb'];
        var axeNeeded;
        for (var i = 0; i < ary.length; i++) {
            if (name.indexOf(ary[i]) != -1) {
                axeNeeded = ary[i];
                i = 1000000;
            }
        }

        if (axeNeeded) {
            //check if in key signature
            for (var i = 0; i < self.keySignature.length; i++) {
                if (name == self.keySignature[i]) {
                    axeNeeded = false;
                }
            }
        } else {
            //check if base name is in key signature
            for (var i = 0; i < self.keySignature.length; i++) {
                if (name[0] == self.keySignature[i][0]) {
                    axeNeeded = 'n';
                }
            }
        }

        return axeNeeded;
    };
    var returnGivenNote = function () {
        var tx = self.givenNote.tx;
        if (tx) {
            moveEle(self.givenNote, -tx, 0);
            for (var i = 0; i < self.givenNote.ledgers.length; i++) moveEle(self.givenNote.ledgers[i], -tx, 0);
            if (self.givenNote.axe) moveEle(self.givenNote.axe, self.givenNote.axe.tx, 0);
            delete self.givenNote.tx;
        }
    };
    var drawGivenNote = function (name) {
        if (name == null) return;
        var verticalIndex = octaveToI(self.clef, name);
        var staffPosition = verticalIndex * (self.spacing / 2);
        var note = self.whole
            .path(wholePath)
            .transform('t' + (self.width / 2 - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
        note.name = name;
        note.i = verticalIndex;
        self.whole.prepend(note);
        self.givenNote = note;
        drawLedgers(note, staffPosition);
        var axe = needsAx(name);
        if (axe) drawNoteAx(note, axe);
    };
    this.drawGivenNote = drawGivenNote;
    var drawGivenChordNote = function () {
        var name = self.givenChordNote;
        if (name == '') return;
        var verticalIndex = octaveToI(self.clef, name);
        var staffPosition = verticalIndex * (self.spacing / 2);
        var note = self.whole
            .path(wholePath)
            .transform('t' + (self.width / 2 - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
        note.name = name;
        note.i = verticalIndex;
        self.whole.prepend(note);
        self.givenChordNote = note;
        drawLedgers(note, staffPosition);
        self.chordNotes.push(note);
        var axe = needsAx(name);
        if (axe) drawNoteAx(note, axe);
    };
    var drawScaleNote = function (name, x) {
        var verticalIndex = octaveToI(self.clef, name);
        var staffPosition = verticalIndex * (self.spacing / 2);
        var note = self.whole.path(wholePath).transform('t' + (x - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
        note.name = name;
        note.i = verticalIndex;
        self.whole.prepend(note);
        drawLedgers(note, staffPosition);
        self.scaleNotes.push(note);
        var axe = needsScaleAx(name);
        if (axe) drawNoteAx(note, axe);
    };
    var drawInterval = function (ary) {
        var n1 = ary[0];
        var n2 = ary[1];
        //bottom note
        if (self.chordNotes) {
            for (var i=0; i<self.chordNotes.length; i++) {
                var note = self.chordNotes[i];
                note.remove();
                for (var j = 0; j < note.ledgers.length; j++) note.ledgers[j].remove();
                if (note.axe) note.axe.remove();
            }
        }
        self.chordNotes = [];
        var verticalIndex = octaveToI(self.clef, n1);
        var staffPosition = verticalIndex * (self.spacing / 2);
        var note = self.whole
            .path(wholePath)
            .transform('t' + (self.width / 2 - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
        note.name = n1;
        note.i = verticalIndex;
        self.whole.prepend(note);
        self.intervalBottom = note;
        drawLedgers(note, staffPosition);
        var axe = needsAx(n1);
        if (axe) drawNoteAx(note, axe);
        self.chordNotes.push(note);

        //top note
        var verticalIndex = octaveToI(self.clef, n2);
        var staffPosition = verticalIndex * (self.spacing / 2);
        var note = self.whole
            .path(wholePath)
            .transform('t' + (self.width / 2 - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
        note.name = n2;
        note.i = verticalIndex;
        self.whole.prepend(note);
        self.intervalTop = note;
        drawLedgers(note, staffPosition);
        self.chordNotes.push(note);

        //handle seconds
        handleSecondConflict(self.chordNotes[1], self.chordNotes[0]);
        var axe = needsAx(n2);
        if (axe) drawNoteAx(note, axe);
        secondsAxe(self.chordNotes[1]);
        handleDrawnIntervalAx(self.chordNotes[0], self.chordNotes[1]);
    };
    this.drawInterval = drawInterval;
    var drawChord = function (ary) {
        self.chordNotes = [];
        for (var i = 0; i < ary.length; i++) {
            var verticalIndex = octaveToI(self.clef, ary[i]);
            var staffPosition = verticalIndex * (self.spacing / 2);
            var note = self.whole
                .path(wholePath)
                .transform('t' + (self.width / 2 - 233) + ' ' + staffPosition + ' s0.0635 -0.0635');
            note.name = ary[i];
            note.i = verticalIndex;
            self.whole.prepend(note);
            self.intervalBottom = note;
            drawLedgers(note, staffPosition);
            var axe = needsAx(ary[i]);
            if (axe) drawNoteAx(note, axe);
            self.chordNotes.push(note);
        }
        handleChordAccidentals();
    };
    this.drawChord = drawChord;
    var handleSecondConflict = function (n1, n2) {
        if (n1.i - n2.i == 1) {
            //proposed note is UNDER given note
            //move given note to the right
            var tx = 12.5;
            moveEle(n2, tx, 0);
            n2.tx = tx;
            if (n2.axe) {
                moveEle(n2.axe, -tx, 0);
                n2.axe.tx = tx;
            }
            for (var i = 0; i < n2.ledgers.length; i++) {
                moveEle(n2.ledgers[i], tx, 0);
            }

            //move proposed note
            moveEle(n1, -tx, 0);

            n1.under = true;
        }
        if (n1.i - n2.i == -1) {
            //proposed note is ABOVE given note
            //move given note to the left
            var tx = -12.5;
            moveEle(n2, tx, 0);
            n2.tx = tx;
            if (n2.axe) {
                moveEle(n2.axe, tx, 0);
                n2.axe.tx = -tx;
            }
            for (var i = 0; i < n2.ledgers.length; i++) {
                moveEle(n2.ledgers[i], tx, 0);
            }

            moveEle(n1, -tx, 0);

            n1.above = true;
        }
        if (n1.i == n2.i) {
            //proposed note is EQUAL to given note
            var tx = -15;
            moveEle(n2, tx, 0);
            n2.tx = tx;
            if (n2.axe) {
                moveEle(n2.axe, tx, 0);
                n2.axe.tx = -tx;
            }
            for (var i = 0; i < n2.ledgers.length; i++) {
                moveEle(n2.ledgers[i], tx, 0);
            }

            moveEle(n1, -tx, 0);

            n1.beside = true;
        }
    };

    var makeLedger = function (note, yIndex) {
        var sp = 7;
        var bb = note.getBBox();
        const ldg = self.whole.rect(bb.x - sp, yIndex * self.spacing, bb.width + 2 * sp, 1);
        note.ledgers.push(ldg);
        self.whole.prepend(ldg);
    };
    var drawLedgers = function (note, pos) {
        note.ledgers = [];
        var bb = note.getBBox();
        var index = pos / (self.spacing / 2);
        var sp = 7;
        var ldg;
        var ln;
        for (var prop in self.ledgerMap) {
            ln = parseInt(prop);
            if (index < parseInt(prop) && ln < 0) makeLedger(note, self.ledgerMap[prop]);
            if (index > parseInt(prop) && ln > 0) makeLedger(note, self.ledgerMap[prop]);
        }
    };
    //shared interactive functions====//

    //interactivity===================================================================================//

    //construction====================================================================================//
    //make paper and attach to id
    this.makePaper = function () {
        this.paper = Snap(this.width, this.height);
        if (document.getElementById(id)) {
            document.getElementById(id).innerHTML = '';
            document.getElementById(id).appendChild(this.paper.node);
        }
        this.whole = this.paper.group();
    };

    //draw lines
    this.drawLines = function () {
        for (var i = 0; i < 5; i++) {
            this.lines.push(this.whole.rect(0, i * this.spacing, this.width, 1));
        }
    };

    //draw clef
    this.drawClef = function () {
        switch (this.clef) {
            case 'treble':
                this.clefSVG = this.whole.path(treblePath).attr({ fill: 'black' }).transform('t-290 -247 s0.07 -0.07');
                break;
            case 'bass':
                this.clefSVG = this.whole.path(bassPath).attr({ fill: 'black' }).transform('t-290 152.3 s0.074 -0.074');
                break;
            case 'alto':
                this.clefSVG = this.whole.path(altoPath).attr({ fill: 'black' }).transform('t-310 36 s0.071 -0.071');
                break;
        }
    };

    //draw key
    var drawKeyAx = function (type, ys, sig) {
        var axe;
        var dx = 18;
        switch (type) {
            case 'sharp':
                for (var i = 0; i < sig.length; i++) {
                    axe = self.whole.path(sharpPath).transform('t' + (dx * i - 65) + ' ' + ys[i] + ' s0.062 -0.062');
                    self.whole.prepend(axe);
                    self.keyAx.push(axe);
                    axe.name = sig[i];
                }
                break;
            case 'flat':
                for (var i = 0; i < sig.length; i++) {
                    axe = self.whole
                        .path(flatPath)
                        .transform('t' + (dx * i - 15) + ' ' + (ys[i] - 160.5) + ' s0.062 -0.062');
                    self.whole.prepend(axe);
                    self.keyAx.push(axe);
                    axe.name = sig[i];
                }
                break;
        }
    };

    this.drawData = function() {
        if (parameters.note) this.drawGivenNote(parameters.note);
    }

    this.drawKey = function (key, mode) {
        if (this.hideKey) {
            key = 'C';
            mode = 'Major';
        }
        var key = key || self.key;
        var mode = mode || self.mode;
        self.keyAx = [];
        if (!key || !mode) return;
        var lnsp = 17.75;
        var ks = keySig(key, mode);
        var sig = ks[0];
        self.keySignature = sig;
        var type = ks[1];
        var base = (14 * lnsp) / 2;
        var trebYs = [
            base - 7 * lnsp,
            base - 5.5 * lnsp,
            base - 7.5 * lnsp,
            base - 6 * lnsp,
            base - 4.5 * lnsp,
            base - 6.5 * lnsp,
            base - 5 * lnsp,
        ];
        var bassYs = [
            base - 6 * lnsp,
            base - 4.5 * lnsp,
            base - 6.5 * lnsp,
            base - 5 * lnsp,
            base - 3.5 * lnsp,
            base - 5.5 * lnsp,
            base - 4 * lnsp,
        ];
        var altoYs = [
            base - 6.5 * lnsp,
            base - 5 * lnsp,
            base - 7 * lnsp,
            base - 5.5 * lnsp,
            base - 4 * lnsp,
            base - 6 * lnsp,
            base - 4.5 * lnsp,
        ];
        var trebYf = [
            base - 5 * lnsp,
            base - 6.5 * lnsp,
            base - 4.5 * lnsp,
            base - 6 * lnsp,
            base - 4 * lnsp,
            base - 5.5 * lnsp,
            base - 3.5 * lnsp,
        ];
        var bassYf = [
            base - 4 * lnsp,
            base - 5.5 * lnsp,
            base - 3.5 * lnsp,
            base - 5 * lnsp,
            base - 3 * lnsp,
            base - 4.5 * lnsp,
            base - 2.5 * lnsp,
        ];
        var altoYf = [
            base - 4.5 * lnsp,
            base - 6 * lnsp,
            base - 4 * lnsp,
            base - 5.5 * lnsp,
            base - 3.5 * lnsp,
            base - 5 * lnsp,
            base - 3 * lnsp,
        ];

        var ys = [];
        if (type == 'sharp') {
            if (self.clef == 'treble') ys = trebYs;
            if (self.clef == 'bass') ys = bassYs;
            if (self.clef == 'alto') ys = altoYs;
        } else {
            if (self.clef == 'treble') ys = trebYf;
            if (self.clef == 'bass') ys = bassYf;
            if (self.clef == 'alto') ys = altoYf;
        }
        drawKeyAx(type, ys, sig);
    };

    //position
    var interactiveMargin = function () {
        switch (self.interactiveType) {
            case 'keysigwriting':
                return 20;
                break;
            default:
                return 60;
        }
    };
    var clefMargin = function () {
        switch (self.clef) {
            case 'treble':
                return 0;
                break;
            case 'bass':
                return 20;
                break;
            default:
                return 0;
        }
    };
    var keySigMargin = function () {
        if (self.key && self.mode) {
            var sig = keySig(self.key, self.mode);
            return 18 * sig[0].length;
        } else {
            return 0;
        }
    };
    var topOffset = function () {
        switch (self.clef) {
            case 'treble':
                return 30;
                break;
            case 'bass':
                return 1.5;
                break;
            case 'alto':
                return 0;
                break;
            default:
                return 0;
                break;
        }
    };
    var buttonOffset = function () {
        switch (self.interactiveType) {
            case 'keysigwriting':
                return 30;
                break;
            case 'notewriting':
                return 0;
                break;
            case 'scalewriting':
                return 0;
                break;
            default:
                return 0;
        }
    };
    this.position = function () {
        var btnOffset = buttonOffset();// + 50; //for cases where an SVG button needs to be drawn
        var height = this.whole.getBBox().height + btnOffset; // + 1 + 2*this.margin + btnOffset;
        this.height = height + this.margin*2;
        this.paper.attr({ height: this.height });
        this.whole.transform('t0 ' + (this.topOffset + this.margin*1 + btnOffset)); //do transform
    };

    var helm = function() {
        switch(self.clef) {
            case 'treble':
                return [
                    'E6',
                    'D6',
                    'C6',
                    'B5',
                    'A5',
                    'G5',
                    'F5',
                    'E5',
                    'D5',
                    'C5',
                    'B4',
                    'A4',
                    'G4',
                    'F4',
                    'E4',
                    'D4',
                    'C4',
                    'B3',
                    'A3',
                    'G3',
                    'F3',
                ];
                break;
            case 'bass':
                return [
                    'G4',
                    'F4',
                    'E4',
                    'D4',
                    'C4',
                    'B3',
                    'A3',
                    'G3',
                    'F3',
                    'E3',
                    'D3',
                    'C3',
                    'B2',
                    'A2',
                    'G2',
                    'F2',
                    'E2',
                    'D2',
                    'C2',
                    'B1',
                    'A1',
                ];
                break;
        }
    }

    //parameters======================//
    var noteNames = function () {
        switch (self.clef) {
            case 'treble':
                return [
                    'E',
                    'D',
                    'C',
                    'B',
                    'A',
                    'G',
                    'F',
                    'E',
                    'D',
                    'C',
                    'B',
                    'A',
                    'G',
                    'F',
                    'E',
                    'D',
                    'C',
                    'B',
                    'A',
                    'G',
                    'F',
                ];
                break;
            case 'bass':
                return [
                    'G',
                    'F',
                    'E',
                    'D',
                    'C',
                    'B',
                    'A',
                    'G',
                    'F',
                    'E',
                    'D',
                    'C',
                    'B',
                    'A',
                    'G',
                    'F',
                    'E',
                    'D',
                    'C',
                    'B',
                    'A',
                ];
                break;
            case 'alto':
                return [
                    'F',
                    'E',
                    'D',
                    'C',
                    'B',
                    'A',
                    'G',
                    'F',
                    'E',
                    'D',
                    'C',
                    'B',
                    'A',
                    'G',
                    'F',
                    'E',
                    'D',
                    'C',
                    'B',
                    'A',
                    'G',
                ];
                break;
        }
    };
    this.ledgerMap = { '-1': '-1', '-3': '-2', '-5': '-3', 9: '5', 11: '6', 13: '7' };
    this.width = parameters.width;
    this.height = parameters.height;
    this.clef = parameters.clef;
    this.noteNames = noteNames();
    this.helmNames = helm();
    this.interactiveType = parameters.interactiveType;
    this.actions = parameters.actions;
    this.actionInputs = parameters.actionInputs;
    this.divisions = parameters.divisions;
    this.givenNote = parameters.givenNote;
    this.givenChordNote = parameters.givenChordNote;
    this.noteLimit = parameters.noteLimit;
    this.scale = parameters.scale;
    this.spacing = 17.75;
    this.margin = interactiveMargin() + clefMargin();
    this.key = parameters.key;
    this.mode = parameters.mode;
    this.hideKey = parameters.hideKey;
    this.keySigMargin = keySigMargin();
    this.topOffset = topOffset(); //based on clef
    this.lines = [];
    //parameters======================//

    //initialization
    this.initialize = function () {
        this.makePaper(); //make snap element
        this.drawLines(); //draw staff lines
        this.drawClef(); //draw clef
        this.drawKey(); //draw key
        this.drawData();
        this.position(); //set height and transform

        if (this.interactiveType) {
            this.makeCover(); //make cover for interactivity
            this.interactive();
        }
        if (this.actions) {
            this.handleActions();
        }
    };
    //construction====================================================================================//
}

var musicStaff;
function start() {
    var parameters = {
        clef: 'treble',
        width: 250,
    };
    musicStaff = new staff('music', parameters);
    musicStaff.initialize();
    musicStaff.drawKey('B', 'Major');
}

//utils==============================================================================//
var svgPoint; // cursorPoint Global SVG Reference Point
function cursorPoint(evt, paper, ele) {
    // Call this with the CLICK/DBLCLICK event arg
    //console.log('Event: ' + evt.x + ',' + evt.y)
    if (!svgPoint) svgPoint = paper.node.createSVGPoint();
    svgPoint.x = evt.x;
    svgPoint.y = evt.y;
    return svgPoint.matrixTransform(ele.node.getScreenCTM().inverse());
}

function handleTrans(t, e, dx, dy) {
    //for safari bullshit
    if (!t) {
        e.node.setAttribute('transform', 'translate(' + dx + ', ' + dy + ')');
        return;
    }
    if (t.indexOf('matrix') != -1) {
        var ary = t.split(',');
        var txt =
            ary[0] +
            ',' +
            ary[1] +
            ',' +
            ary[2] +
            ',' +
            ary[3] +
            ',' +
            (parseFloat(ary[4]) + dx) +
            ',' +
            (parseFloat(ary[5].replace(')', '')) + dy) +
            ')';
        e.node.setAttribute('transform', txt);
    }
    if (t.indexOf('translate') != -1) {
        var ary = t.split(',');
        var x = parseFloat(ary[0].replace('translate(', '')) + dx;
        var y = parseFloat(ary[1].replace(' ', '').replace(')', '')) + dy;
        e.node.setAttribute('transform', 'translate(' + x + ', ' + y + ')');
    }
}

function moveEle(e, dx, dy) {
    if (navigator.platform.indexOf('Mac') != -1) {
        var t = e.node.getAttribute('transform');
        handleTrans(t, e, dx, dy);
    } else {
        if (e.node.transform.baseVal[0]) {
            e.node.transform.baseVal[0].matrix.e += dx;
            e.node.transform.baseVal[0].matrix.f += dy;
        } else {
            e.transform('t' + dx + ' ' + dy);
        }
    }
}

export function ItoOctave(clef, nI) {
    switch (clef) {
        case 'treble':
            if (nI < -3) return 6;
            if (nI < 4) return 5;
            if (nI < 11) return 4;
            if (nI < 18) return 3;
            break;
        case 'bass':
            if (nI < -1) return 4;
            if (nI < 6) return 3;
            if (nI < 13) return 2;
            if (nI < 20) return 1;
            break;
        case 'alto':
            if (nI < -2) return 5;
            if (nI < 5) return 4;
            if (nI < 12) return 3;
            if (nI < 19) return 2;
            break;
    }
}

function octaveToI(clef, note) {
    var n = note[0]; //note name
    var o = note[note.length - 1]; //octave number
    var hnotes1 = [
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
    ];
    var hnotes2 = [
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
    ];
    var hnotes3 = [
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
    ];
    var ocs1 = [6, 6, 6, 5, 5, 5, 5, 5, 5, 5, 4, 4, 4, 4, 4, 4, 4, 3, 3, 3, 3];
    var ocs2 = [4, 4, 4, 4, 4, 3, 3, 3, 3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 2, 1, 1];
    var ocs3 = [4, 4, 4, 4, 3, 3, 3, 3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 2, 1, 1, 1];

    let hnotes;
    let ocs;
    switch (clef) {
        case 'treble':
            hnotes = hnotes1;
            ocs = ocs1;
            break;
        case 'bass':
            hnotes = hnotes2;
            ocs = ocs2;
            break;
        case 'alto':
            hnotes = hnotes3;
            ocs = ocs3;
            break;
        default:
            throw new Error('Invalid clef');
    }

    for (var i = 0; i < hnotes.length; i++) {
        if (n == hnotes[i] && o == ocs[i]) return i - 6;
    }
}

function copyAry(ary) {
    var nAry = [];
    for (var i = 0; i < ary.length; i++) nAry.push(ary[i]);
    return nAry;
}

function cleanNum(note) {
    return note.replace('1','').replace('2','').replace('3','').replace('4','').replace('5','').replace('6','').replace('7','').replace('8','');
}

export function keySig(key, mode) {
    if (mode.toLowerCase().indexOf('minor') != -1) key = key[0].toLowerCase() + key.slice(1);
    var sig = [];
    var type;
    switch (key) {
        case 'C':
            type = '';
            break;
        case 'a':
            type = '';
            break;
        case 'G':
            sig = ['F♯'];
            break;
        case 'e':
            sig = ['F♯'];
            break;
        case 'D':
            sig = ['F♯', 'C♯'];
            break;
        case 'b':
            sig = ['F♯', 'C♯'];
            break;
        case 'A':
            sig = ['F♯', 'C♯', 'G♯'];
            break;
        case 'f♯':
            sig = ['F♯', 'C♯', 'G♯'];
            break;
        case 'E':
            sig = ['F♯', 'C♯', 'G♯', 'D♯'];
            break;
        case 'c♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯'];
            break;
        case 'B':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯'];
            break;
        case 'g♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯'];
            break;
        case 'F♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯', 'E♯'];
            break;
        case 'd♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯', 'E♯'];
            break;
        case 'C♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯', 'E♯', 'B♯'];
            break;
        case 'a♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯', 'E♯', 'B♯'];
            break;
        case 'F':
            sig = ['B\u0231'];
            break;
        case 'd':
            sig = ['B\u0231'];
            break;
        case 'B\u0231':
            sig = ['B\u0231', 'E\u0231'];
            break;
        case 'g':
            sig = ['B\u0231', 'E\u0231'];
            break;
        case 'E\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231'];
            break;
        case 'c':
            sig = ['B\u0231', 'E\u0231', 'A\u0231'];
            break;
        case 'A\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231'];
            break;
        case 'f':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231'];
            break;
        case 'D\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231'];
            break;
        case 'b\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231'];
            break;
        case 'G\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231', 'C\u0231'];
            break;
        case 'e\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231', 'C\u0231'];
            break;
        case 'C\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231', 'C\u0231', 'F\u0231'];
            break;
        case 'a\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231', 'C\u0231', 'F\u0231'];
            break;
    }
    if (sig.indexOf('F♯') != -1) type = 'sharp';
    if (sig.indexOf('B\u0231') != -1) type = 'flat';
    return [sig, type];
}
//utils==============================================================================//

//paths==============================================================================//
//notes
var wholePath =
    'M215 112c-50 0 -69 -43 -69 -88c0 -77 57 -136 134 -136c50 0 69 43 69 88c0 77 -57 136 -134 136zM495 0c0 -43 -35' +
    ' -76 -73 -97c-53 -30 -113 -41 -174 -41s-122 11 -175 41c-38 21 -73 54 -73 97s35 76 73 97c53 30 114 41 175 41s121 -11' +
    ' 174 -41 c38 -21 73 -54 73 -97z';
var closedPath =
    'M224 136c53 0 105 -26 105 -86c0 -71 -57 -122 -104 -150c-36 -22 -78 -36 -120 -36c-53 0 -105 26 -105' +
    ' 86c0 71 58 122 105 150c36 22 77 36 119 36z';
var openPath =
    'M318 67c0 25 -21 42 -43 42c-4 0 -9 -1 -13 -2c-31 -9 -79 -41 -117 -65s-85 -55 -106 -80c-7 -8 -11 -19 -11 -29c0 -25 21 -42 43 -42c4' +
    ' 0 8 1 12 2c31 9 79 41 117 65s86 55 107 80c7 8 11 19 11 29zM267 136c44 0 79 -20 79 -68c0 -19 -6 -38 -12 -57c-13 -37 -32 -73 -65 -95c-54' +
    ' -36 -115 -52 -190 -52c-44 0 -79 20 -79 68c0 19 6 38 12 57c13 37 31 73 64 95c54 36 116 52 191 52z';

//clefs
var altoPath =
    'M318 0c0 -33 7 -73 45 -73c29 0 57 31 88 31c123 0 229 -89 229 -208c0 -169 -93 -250 -265 -250c-83 0 -152 61 -152 142c0 42 34 77 76 77s76 -35 76 -77c0 -39 -49 -37 -49 -76c0 -23 24 -38 49 -38c116 0 140 90 140 222c0 106 -12 180 -104 180 c-72 0 -119 -71 -119 -149c0 -9 -7 -14 -14 -14s-13 5 -13 14c0 76 -31 147 -84 200v-471c0 -6 -4 -10 -10 -10h-21c-6 0 -10 4 -10 10v980c0 6 4 10 10 10h21c6 0 10 -4 10 -10v-471c53 53 84 124 84 200c0 9 6 14 13 14s14 -5 14 -14c0 -78 47 -149 119 -149 c92 0 104 74 104 180c0 132 -24 222 -140 222c-25 0 -49 -15 -49 -38c0 -39 49 -37 49 -76c0 -42 -34 -77 -76 -77s-76 35 -76 77c0 81 69 142 152 142c172 0 265 -81 265 -250c0 -119 -106 -208 -229 -208c-31 0 -59 31 -88 31c-38 0 -45 -40 -45 -73zM129 -500h-119 c-6 0 -10 4 -10 10v980c0 6 4 10 10 10h119c6 0 10 -4 10 -10v-980c0 -6 -4 -10 -10 -10z';
var treblePath =
    'M374 260c5 0 10 1 15 1c154 0 254 -127 254 -259c0 -76 -33 -153 -107 -208c-22 -17 -47 -29 -73 -37c3 -36 5 -72 5 -108c0 -19 0' +
    ' -37 -1 -56c-7 -120 -88 -226 -206 -226c-108 0 -195 87 -195 195c0 58 53 104 112 104c54 0 95 -48 95 -104c0 -52 -43 -95 -95 -95 c-12 0 -24' +
    ' 4 -35 9c26 -41 70 -70 121 -70c96 0 157 93 163 193c1 18 2 35 2 53c0 32 -2 64 -5 96c-29 -5 -58 -8 -89 -8c-188 0 -333 171 -333 372c0 178 133' +
    ' 306 251 440c-18 63 -35 126 -42 191c-6 51 -7 102 -7 154c0 116 55 226 150 295c2 2 5 3 8 3s6 -1 8 -3c70 -84 132 -246 132 -359c0 -144 -87 -256' +
    ' -183 -365c20 -69 39 -138 55 -208zM459 -205c69 24 117 96 117 166c0 92 -69 184 -179 193c25 -117 48 -235 62 -359zM71 31c0 -136 131 -251 267' +
    ' -251c28 0 55 2 82 6c-15 128 -38 247 -64 367c-79 -9 -125 -62 -125 -121c0 -44 26 -92 82 -124c4 -4 7 -9 7 -14c0 -11 -9 -21 -20 -21c-3 0 -6 1' +
    ' -9 2c-80 43 -116 114 -116 184c0 88 57 173 158 196c-14 60 -30 119 -46 178c-108 -121 -216 -242 -216 -402zM411 1050c-99 -50 -161 -151 -161' +
    ' -262c0 -76 15 -137 33 -200c82 97 149 198 149 325 c0 58 -4 84 -21 137z';
var bassPath =
    'M556 -125c0 29 23 52 52 52s52 -23 52 -52s-23 -52 -52 -52s-52 23 -52 52zM556 125c0 29 23 52 52 52s52 -23 52' +
    ' -52s-23 -52 -52 -52s-52 23 -52 52zM233 261c171 0 292 -86 292 -248c0 -263 -264 -415 -517 -521c-2 -2 -5 -3 -8 -3c-6 0 -11' +
    ' 5 -11 11c0 3 1 6 3 8 c203 118 415 265 415 494c0 121 -64 237 -174 237c-79 0 -138 -57 -164 -133c14 8 28 13 43 13c55 0 100' +
    ' -45 100 -100c0 -58 -44 -107 -100 -107c-60 0 -112 48 -112 107c0 132 103 242 233 242z';

//accidentals
var sharpPath =
    'M215 -316c0 -9 -8 -17 -17 -17s-17 8 -17 17v151l-87 -31v-162c0 -9 -8 -17 -17 -17s-17 8 -17 17v150l-39' +
    ' -13c-10 -4 -21 4 -21 15v64c0 7 5 13 11 15l49 18v164l-39 -14c-10 -4 -21 4 -21 15v65c0 7 5 13 11 15l49 17v163c0 9 8' +
    ' 17 17 17s17 -8 17 -17v-151l87 31v162 c0 9 8 17 17 17s17 -8 17 -17v-150l39 13c10 4 21 -4 21 -15v-64c0 -7 -5 -13 -11' +
    ' -15l-49 -18v-164l39 14c10 4 21 -4 21 -15v-65c0 -7 -5 -13 -11 -15l-49 -17v-163zM94 67v-164l87 30v164z';
var doublesharpPath =
    'M63.4219 333.7031 L43.5938 333.7031 L43.5938 321.4688 Q43.5938 315.8438 36 315.8438 Q33.6094 315.8438' +
    ' 31.2188 317.3906 Q28.4062 319.2188 28.4062 321.4688 L28.4062 333.7031 L9.4219 333.7031 L9.4219 314.7188 L21.6562' +
    ' 314.7188 Q27.2812 314.7188 27.2812 307.125 Q27.2812 304.7344 25.5938 302.1328 Q23.9062 299.5312 21.6562 299.5312' +
    ' L9.4219 299.5312 L9.4219 280.4062 L28.4062 280.4062 L28.4062 292.6406 Q28.4062 294.8906 31.2188 296.7188 Q33.6094' +
    ' 298.2656 36 298.2656 Q43.5938 298.2656 43.5938 292.6406 L43.5938 280.4062 L63.4219 280.4062 L63.4219 299.5312 L51.1875' +
    ' 299.5312 Q48.9375 299.5312 47.25 302.1328 Q45.5625 304.7344 45.5625 307.125 Q45.5625 314.7188 51.1875 314.7188 L63.4219' +
    ' 314.7188 L63.4219 333.7031 Z';
var flatPath =
    'M27 41l-1 -66v-11c0 -22 1 -44 4 -66c45 38 93 80 93 139c0 33 -14 67 -43 67c-31 0 -52 -30 -53 -63zM-15 -138l-12 595c8' +
    ' 5 18 8 27 8s19 -3 27 -8l-7 -345c25 21 58 34 91 34c52 0 89 -48 89 -102c0 -80 -86 -117 -147 -169c-15 -13 -24 -38 -45 -38 c-13 0 -23 11 -23 25z';
var doubleflatPath =
    'M28.125 392.4844 L28.125 266.625 L35.4375 266.625 L35.4375 352.4062 L42.4688 343.6875 Q49.2188 337.5 56.5312 337.5' +
    ' Q61.3125 337.5 65.9531 339.6094 Q70.5938 341.7188 72.8438 346.3594 Q75.0938 351 75.0938 357.4688 Q74.8125 364.2188 68.625 371.25' +
    ' Q62.4375 378.2812 51.1875 383.9062 Q39.9375 389.5312 28.125 392.4844 ZM75.9375 392.4844 L75.9375 266.625 L83.25 266.625 L83.25' +
    ' 352.4062 L90.2812 343.6875 Q97.0312 337.5 104.3438 337.5 Q109.125 337.5 113.7656 339.6094 Q118.4062 341.7188 120.6562 346.3594' +
    ' Q122.9062 351 122.9062 357.4688 Q122.625 364.2188 116.4375 371.25 Q110.25 378.2812 99 383.9062 Q87.75 389.5312 75.9375 392.4844' +
    ' ZM35.4375 378.8438 Q51.4688 373.3594 56.9531 367.6641 Q62.4375 361.9688 62.4375 358.0312 Q62.4375 355.2188 62.0156 353.5312' +
    ' Q61.5938 351.8438 59.7656 350.2969 Q57.9375 348.75 55.6875 348.75 Q53.4375 348.75 49.7812 350.1562 Q46.125 351.5625 43.5938' +
    ' 354.0938 L35.4375 362.25 L35.4375 378.8438 ZM83.25 378.8438 Q99.2812 373.3594 104.7656 367.6641 Q110.25 361.9688 110.25' +
    ' 358.0312 Q110.25 355.2188 109.8281 353.5312 Q109.4062 351.8438 107.5781 350.2969 Q105.75 348.75 103.5 348.75 Q101.25 348.75' +
    ' 97.5938 350.1562 Q93.9375 351.5625 91.4062 354.0938 L83.25 362.25 L83.25 378.8438 Z';
var naturalPath =
    'M-8 375c8 4 17 7 26 7s17 -3 25 -7l-3 -183l106 20h3c10 0 18 -7 18 -17l7 -570c-8 -4 -16 -7 -25 -7s-17 3 -25 7l3 183l-106 -20h-3c-10 0 -18 7 -18 17zM131 112l-92 -17l-3 -207l92 17z';

//time signatures
var onePath =
    'M122 503c18 0 34 -16 53 -16c15 0 29 7 42 13c1 1 3 1 4 1c7 0 12 -7 12 -17v-367c0 -48 28 -94 73 -94c8 0 12 -5 12 -11s-4 -12 -12 -12c-44 0 -87 13 -131 13s-87 -13 -131 -13c-8 0 -12 6 -12 12s4 11 12 11c45 0 73 46 73 94v245c0 12 -9 19 -17 19 c-4 0 -8 -3 -10 -7l-64 -136c-3 -6 -7 -9 -12 -9c-8 0 -16 6 -16 14c0 2 1 5 2 7l117 250c1 2 3 3 5 3z';
var twoPath =
    'M23 11c-1 -7 -6 -11 -11 -11c-6 0 -12 4 -12 11c0 175 235 165 235 344c0 60 -18 122 -70 122c-26 0 -51 -14 -51 -37c0 -27 35 -41 35 -68c0 -38 -30 -69 -68 -69s-69 31 -69 69c0 76 72 128 153 128c96 0 187 -56 187 -145c0 -153 -151 -159 -249 -220c9 2 17 3 26 3 c33 0 70 -11 105 -36c21 -15 46 -23 66 -23c21 0 39 9 44 29c2 6 6 9 11 9c6 0 12 -5 12 -12c0 -67 -90 -105 -138 -105c-36 0 -71 14 -96 44c-13 16 -31 22 -48 22c-30 0 -59 -20 -62 -55z';
var threePath =
    'M150 477c-29 0 -59 -8 -59 -33c0 -22 36 -25 36 -47c0 -32 -26 -58 -58 -58s-57 26 -57 58c0 65 67 103 138 103c91 0 169 -33 169 -114c0 -43 -4 -82 -42 -100c-9 -4 -14 -12 -14 -21s5 -18 14 -22c41 -19 56 -57 56 -103c0 -92 -79 -140 -179 -140c-79 0 -154 42 -154 113c0 36 30 66 66 66s65 -30 65 -66c0 -25 -40 -28 -40 -53c0 -27 32 -37 63 -37c49 0 63 61 63 117v16c0 54 -2 94 -50 94h-80c-10 0 -14 8 -14 15s4 14 14 14h80c49 0 50 43 50 99v8c0 54 -18 91 -67 91z';
var fourPath =
    'M204 307c22 14 44 29 60 50c11 15 19 33 27 50c2 4 5 6 9 6c6 0 13 -5 13 -14v-245h72c10 0 15 -7 15 -14s-5 -15 -15 -15h-72v-8c0 -48 27 -94 72 -94c8 0 12 -5 12 -11s-4 -12 -12 -12c-44 0 -87 13 -131 13s-87 -13 -131 -13c-8 0 -12 6 -12 12s4 11 12 11 c45 0 73 46 73 94v8h-167c-23 0 -31 14 -31 23c0 3 1 5 2 6c80 93 138 207 138 330c0 9 6 16 13 16h3c23 -7 47 -13 71 -13s48 6 71 13c2 1 3 1 5 1c11 0 18 -10 12 -17l-284 -330h167v131c0 8 1 17 8 22z';
var fivePath =
    'M46 500c46 -8 91 -13 137 -13s92 5 138 13h4c12 0 19 -10 13 -16c-68 -68 -166 -93 -263 -93c-9 0 -17 -8 -17 -17v-96c28 28 66 43 105 43c116 0 186 -51 186 -161c0 -94 -87 -160 -186 -160c-82 0 -163 39 -163 113c0 36 30 66 66 66s65 -30 65 -66 c0 -25 -40 -28 -40 -53c0 -30 37 -37 72 -37c57 0 70 71 70 137c0 63 -15 132 -70 132c-42 0 -83 -12 -107 -45c-3 -4 -8 -6 -12 -6c-8 0 -15 6 -15 15v228c0 9 7 16 15 16h2z';
var sixPath =
    'M170 256c-51 0 -53 -44 -53 -102v-14v-15c0 -58 2 -102 53 -102c57 0 61 51 61 117s-4 116 -61 116zM117 266c17 7 34 13 53 13c101 0 170 -44 170 -139s-69 -140 -170 -140c-112 0 -170 124 -170 250c0 128 72 250 190 250c71 0 138 -39 138 -103c0 -36 -29 -66 -65 -66 s-66 30 -66 66c0 24 39 25 39 49c0 20 -23 31 -46 31c-66 0 -75 -65 -75 -139c0 -24 2 -48 2 -72z';
var sevenPath =
    'M67 410c-31 0 -38 -43 -38 -70v-75c0 -10 -7 -15 -14 -15s-15 5 -15 15v220c0 10 8 15 15 15s14 -5 14 -15v-14c0 -9 12 -19 15 -15c21 29 48 44 78 44c29 0 58 -13 84 -39c12 -12 27 -17 43 -17c30 0 63 19 84 50c3 5 7 6 11 6c8 0 16 -6 16 -14c0 -3 -1 -6 -3 -9 c-83 -118 -158 -249 -158 -387c0 -25 3 -50 8 -76c2 -9 -5 -17 -13 -17c-1 0 -3 1 -4 1c-24 7 -48 15 -73 15s-50 -8 -74 -15c-1 0 -3 -1 -4 -1c-9 0 -16 9 -12 17c54 128 134 242 216 355c-20 -11 -40 -16 -59 -16c-22 0 -42 8 -60 26s-39 31 -57 31z';
var eightPath =
    'M259 288c27 29 49 62 49 102c0 55 -57 87 -117 87c-47 0 -75 -36 -75 -71c0 -20 10 -40 30 -52zM289 271c55 -31 83 -79 83 -127c0 -73 -66 -144 -189 -144c-94 0 -183 54 -183 140c0 50 39 85 76 120c-41 28 -61 67 -61 106c0 68 61 134 176 134c81 0 161 -38 161 -110 c0 -47 -30 -85 -63 -119zM106 242c-33 -29 -62 -59 -62 -102c0 -69 66 -117 139 -117c54 0 86 42 86 83c0 24 -10 48 -35 62z';
var ninePath =
    'M170 244c51 0 53 44 53 102v14v15c0 58 -2 102 -53 102c-57 0 -61 -51 -61 -117s4 -116 61 -116zM223 234c-17 -7 -34 -13 -53 -13c-101 0 -170 44 -170 139s69 140 170 140c112 0 170 -124 170 -250c0 -128 -72 -250 -190 -250c-71 0 -138 39 -138 103c0 36 29 66 65 66 s66 -30 66 -66c0 -24 -39 -25 -39 -49c0 -20 23 -31 46 -31c66 0 75 65 75 139c0 24 -2 48 -2 72z';

//brace
var bracePath =
    'M-12 -656c0 -352 -132 -696 -132 -1032c0 -180 36 -344 156 -484c4 -4 8 -8 8 -12c0 -8 -12 -16 -20 -16c-4 0 -8 0 -12 4c-160 184 -216 416 -216 656c0 360 140 712 140 1052c0 176 -40 344 -160 480v8v8c120 136 160 304 160 480c0 340 -140 692 -140 1052 c0 240 56 472 216 656c4 4 8 4 12 4c8 0 20 -8 20 -16c0 -4 -4 -8 -8 -12c-120 -140 -156 -304 -156 -484c0 -336 132 -680 132 -1032c0 -240 -52 -472 -208 -656c156 -184 208 -416 208 -656z';

//rest paths
var sixteenthRestPath =
    'M66 -500l101 327c-34 -12 -70 -23 -106 -23c-46 0 -87 33 -87 79c0 40 32 72 72 72c25 0 49 -15 57 -39c10 -28 5 -59 34 -59c16 0 55 51 60 68l46 151c-34 -12 -68 -22 -104 -22c-46 0 -87 33 -87 79c0 40 33 72 73 72c25 0 48 -15 56 -39c10 -28 5 -59 34 -59 c15 0 50 49 57 63c6 12 23 12 28 0l-179 -670c-8 -7 -17 -10 -27 -10s-20 3 -28 10z';
var eighthRestPath =
    'M72 -250l117 326c-34 -12 -69 -22 -105 -22c-46 0 -87 33 -87 79c0 40 33 72 73 72c25 0 48 -15 56 -39c10 -28 6 -59 35 -59c16 0 54 48 61 63c6 12 23 12 28 0l-123 -420c-8 -7 -17 -10 -27 -10s-20 3 -28 10z';
var quarterRestPath =
    'M-23 -116c0 28 11 42 40 42c33 0 78 -13 119 -31l-132 158c-7 9 -10 17 -10 25c0 34 50 66 87 99c25 22 37 52 37 83c0 24 -8 49 -25 69l-35 42c-3 3 -4 7 -4 10c0 9 9 15 17 15c4 0 8 -1 11 -5l151 -180c7 -9 10 -17 10 -25c0 -34 -50 -66 -87 -99 c-25 -22 -37 -52 -37 -83c0 -24 7 -49 24 -69l84 -99c3 -3 4 -7 4 -10c0 -9 -8 -16 -16 -16c-4 0 -9 2 -12 6c-18 21 -63 38 -97 38c-41 0 -53 -26 -53 -67c0 -35 11 -74 28 -94c5 -6 0 -13 -6 -13c-2 0 -4 0 -6 2c-45 54 -92 148 -92 202z';
var halfRestPath = 'M365 0h-355c-6 0 -10 4 -10 10v136c0 6 4 10 10 10h355c6 0 10 -4 10 -10v-136c0 -6 -4 -10 -10 -10z';
var wholeRestPath =
    'M365 -156h-355c-6 0 -10 4 -10 10v136c0 6 4 10 10 10h355c6 0 10 -4 10 -10v-136c0 -6 -4 -10 -10 -10z';

//flag paths
var sixFlagPath =
    'M0 0v-500h-16v500h16zM0 0c0 -178 203 -292 203 -470c0 -33 -9 -65 -23 -95c18 -37 29 -75 29 -117c0 -63 -16 -125 -42 -183c-5 -8 -12 -12 -19 -12c-13 0 -26 11 -23 27c26 52 42 110 42 168c0 97 -95 191 -167 257v212c0 -118 91 -206 153 -304c5 15 7 31 7 47 c0 96 -91 190 -160 257v213z';
var eighthFlagPath =
    'M0 0h-16v250h16c98 58 227 148 227 259c0 57 -16 112 -41 163c-3 16 10 27 23 27c7 0 15 -3 20 -11c25 -56 40 -117 40 -179c0 -204 -269 -305 -269 -509z';
var eighthFlagPathU =
    'M0 0h-16v250h16c98 58 227 148 227 259c0 57 -16 112 -41 163c-3 16 10 27 23 27c7 0 15 -3 20 -11c25 -56 40 -117 40 -179c0 -204 -269 -305 -269 -509z';
var eighthFlag =
    'M0 0c0 -198 209 -335 209 -533c0 -71 -16 -141 -42 -207c-5 -8 -12 -12 -19 -12c-13 0 -26 11 -23 27c26 61 42 126 42 192c0 104 -95 208 -167 283h-16v250h16z';
var sixteenthFlag =
    'M0 0c0 -178 203 -292 203 -470c0 -33 -9 -65 -23 -95c18 -37 29 -75 29 -117c0 -63 -16 -125 -42 -183c-5 -8 -12 -12 -19 -12c-13 0 -26 11 -23 27c26 52 42 110 42 168c0 97 -95 191 -167 257v-75h-16v500h16zM153 -517c5 15 7 31 7 47c0 96 -91 190 -160 257 c0 -118 91 -206 153 -304z';
var eighthFlagI =
    'M0 0h-16v250h16c98 58 227 148 227 259c0 57 -16 112 -41 163c-3 16 10 27 23 27c7 0 15 -3 20 -11c25 -56 40 -117 40 -179c0 -204 -269 -305 -269 -509z';
var sixteenthFlagI =
    'M0 205c92 51 214 131 214 233c0 24 -5 48 -14 70c-80 -89 -200 -160 -200 -283v-20zM269 663c0 -44 -15 -82 -38 -116c16 -34 25 -71 25 -109c0 -181 -256 -257 -256 -438h-16v500h16v-70c98 49 227 127 227 233c0 21 -6 42 -14 62c-3 16 10 27 23 27c30 0 33 -63 33 -89z';

var rnGrad2 = 'l(-0.25,-0.25,1.25,1.25)#A0AFB3-#FFFFFF';
//paths==============================================================================//
