//import { useStytch, useStytchUser } from "@stytch/react";
import { useStytch, useStytchSession } from '@stytch/react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";

/*
During both the Magic link and OAuth flow, Stytch will redirect the user back to your application to a specified redirect URL (see Login.js). 
Stytch will append query parameters to the redirect URL which are then used to complete the authentication flow. 
A redirect URL for this example app will look something like: http://localhost:3000/?stytch_token_type=magic_links&token=abc123

TokenAuthenticator will detect the presence of a token in the query parameters, and attempt to authenticate it.
On successful authentication, a session will be created and the user will be shown Profile.js 
*/
const TokenAuthenticator = () => {
  const stytch = useStytch();
  const { session } = useStytchSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (session) {
      // The user is authenticated. You can redirect the user to their authenticaed experience 
      navigate('/')
    } else {
        const token = new URLSearchParams(window.location.search).get('token');
        stytch.magicLinks.authenticate(token, {
          session_duration_minutes: 60,
        });
    }
    // If the stytch SDK is available, and there is no existing user check for a token value in query params
    /*if (stytch && !user) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");
      const tokenType = queryParams.get("stytch_token_type");

      // If a token is found, authenticate it with the appropriate method
      if (session) {
      } else {
          stytch.magicLinks.authenticate(token, {
            session_duration_minutes: 60,
          });
      }
      console.log("authed the user: "+user)
    }*/
  }, [stytch, session]);
  return (<div>Loading</div>);
};

export default TokenAuthenticator;
