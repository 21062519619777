import './App.css';
import React from 'react';
import { useStytchUser, useStytchSession } from "@stytch/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './components/Login.js';
import Reset from './components/Reset.js';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStytch } from "@stytch/react";
import Authenticate from './components/TokenAuthenticator.js';
//import Reset from './Reset.js';
import Tutor from './Tutor.js';
import Navbar from './features/Navbar.js';
import ShowNavbar from './features/ShowNavbar.js';

//if not logged in -> login page
//password reset -> have a token
//one time password? -> magic token
//if logged in already -> go to session

function App() {
  const [loading, setLoading] = useState(true);
  const { user } = useStytchUser();

  console.log('hello from App.js');

  //if no user, could be logging in, could be on authenticate page for token, could be resetting
  if (!user) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/authenticate" element={<Authenticate/>}/>
          <Route path="/reset" element={<Reset/>}/>
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Tutor/>}/>
        <Route path="/authenticate" element={<Authenticate/>}/>
        <Route path="/reset" element={<Reset/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

/*export default function App() {
  const { user } = useStytchUser();
  const { session } = useStytchSession();

  //
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Tutor/>}/>
        </Routes>
      </BrowserRouter>
  )
}*/
