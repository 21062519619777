import { createSlice } from '@reduxjs/toolkit'

export const matthewSlice = createSlice({
  name: 'matthew',
  initialState: {
    talking: false,
    actions: [],
  },
  reducers: {
    setTalking: (state, action) => {
      state.talking = action.payload
    },
    setActions: (state, action) => {
      state.actions = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTalking, setActions } = matthewSlice.actions

export default matthewSlice.reducer