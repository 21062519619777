export function makeClick(callback) {
    document.body.onkeydown = function(e){
        if (e.keyCode==13) {
            if (callback) callback()
            console.log('pressed enter');
        }
        //console.log(String.fromCharCode(e.keyCode)+" --> "+e.keyCode);
    };
}

export function removeClick() {

}

export default makeClick;